import { p as proxyCustomElement, H, h, c as Host } from './p-92c4a1ea.js';
import './p-a57042e3.js';
import { b as accessibility } from './p-756e7dc5.js';
import { a as ComponentUtils } from './p-de830ba7.js';
const sideMenuCss = ".sc-owc-side-menu-h{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;font-family:var(--one-typography-font-family);font-size:var(--one-typography-desktop-body-1-font-size);line-height:var(--one-typography-desktop-body-1-line-height);-webkit-font-kerning:var(--one-typography-desktop-body-1-font-kerning);font-kerning:var(--one-typography-desktop-body-1-font-kerning);font-weight:var(--one-text-font-weight-regular);-webkit-box-sizing:border-box;box-sizing:border-box;background:var(--one-color-background-base);outline:none;overflow:hidden;height:100%;width:calc(var(--one-spacing-base-35) * 2 + var(--one-spacing-size-7-xs) * 80);-webkit-transition-property:width;transition-property:width;-webkit-transition-duration:0.25s;transition-duration:0.25s}.sc-owc-side-menu-h .owc-side-menu__content-wrapper.sc-owc-side-menu{margin:0;-ms-flex-positive:1;flex-grow:1;max-height:100%;overflow:auto;width:100%}@supports (overflow: overlay){.sc-owc-side-menu-h .owc-side-menu__content-wrapper.sc-owc-side-menu{overflow:overlay}}.sc-owc-side-menu-h [slot=header].sc-owc-side-menu{min-height:-webkit-fit-content;min-height:-moz-fit-content;min-height:fit-content}.sc-owc-side-menu-h [slot=footer].sc-owc-side-menu{z-index:2;min-height:-webkit-fit-content;min-height:-moz-fit-content;min-height:fit-content}@media (forced-colors: active){.sc-owc-side-menu-h{border:var(--one-spacing-outline-regular) solid canvastext}}";
const OwcSideMenuStyle0 = sideMenuCss;
const SideMenu = /*@__PURE__*/proxyCustomElement(class SideMenu extends H {
  constructor() {
    super();
    this.__registerHost();
    this.component = new ComponentUtils(this);
  }
  render() {
    const hostClasses = this.component.getHostBemClasses();
    const contentWrapper = this.component.getChildBemClasses('content-wrapper', hostClasses);
    return h(Host, Object.assign({
      key: '475c74e3ad1b5cd36d0cb445cf42b78f9786e413',
      class: hostClasses
    }, accessibility({
      role: 'navigation'
    })), h("slot", {
      key: '467c2cc1d61b610d04b5ec1461c6250c3651e4ef',
      name: "header"
    }), h("div", {
      key: '2eb081162b5409473aad94dc5544705e65084309',
      class: contentWrapper
    }, h("slot", {
      key: '4fdaefcf76c3dd9bd5f0e4c6ed73384104eebb98'
    })), h("slot", {
      key: 'a524d31cd98e20b1689903e2cbb5e03e1623934d',
      name: "footer"
    }));
  }
  get hostElement() {
    return this;
  }
  static get style() {
    return OwcSideMenuStyle0;
  }
}, [6, "owc-side-menu"]);
function defineCustomElement$1() {
  if (typeof customElements === "undefined") {
    return;
  }
  const components = ["owc-side-menu"];
  components.forEach(tagName => {
    switch (tagName) {
      case "owc-side-menu":
        if (!customElements.get(tagName)) {
          customElements.define(tagName, SideMenu);
        }
        break;
    }
  });
}
const OwcSideMenu = SideMenu;
const defineCustomElement = defineCustomElement$1;
export { OwcSideMenu, defineCustomElement };