import { i as isSSR, p as SUBSCRIPTIONS_KEY, W as findNodeInEventPath, g as getDocument, au as getElement } from './p-a57042e3.js';
import { a as SubscriptionList } from './p-bad6a4c5.js';
const DEFAULT_TRIGGER_EVENT = 'click';
const CLICK_OUTSIDE_ENABLE_SUBJECT_KEY = 'click-outside-enable-subject';
function isExcluded(target, excludedNodes) {
  if (target && excludedNodes) {
    for (const excludedNode of excludedNodes) {
      if (excludedNode.contains(target)) {
        return true;
      }
    }
  }
  return false;
}
function getTriggerEvents(opt) {
  return (opt.triggerEvents || DEFAULT_TRIGGER_EVENT).split(',').map(e => e.trim());
}
function getExcludedNodes(opt) {
  var _a;
  const query = ['owc-virtual-keyboard', opt.exclude].filter(Boolean).join(', ');
  try {
    return Array.from(((_a = getDocument()) === null || _a === void 0 ? void 0 : _a.querySelectorAll(query)) || []);
  } catch (err) {
    return [];
  }
}
function ClickOutside(opts) {
  return (proto, methodName) => {
    const {
      componentDidLoad,
      disconnectedCallback,
      connectedCallback
    } = proto;
    const handlerMethodName = ['co', methodName].join('-');
    function removeListeners(self) {
      if (!isSSR()) {
        for (const e of getTriggerEvents(opts)) {
          window.removeEventListener(e, self[handlerMethodName], true);
        }
      }
    }
    function addListeners(self) {
      removeListeners(self);
      if (!isSSR()) {
        for (const e of getTriggerEvents(opts)) {
          window.addEventListener(e, self[handlerMethodName], true);
        }
      }
    }
    function init(self) {
      const host = getElement(self);
      const method = self[methodName];
      !(host[SUBSCRIPTIONS_KEY] instanceof SubscriptionList) && (host[SUBSCRIPTIONS_KEY] = new SubscriptionList());
      const subscriptions = host[SUBSCRIPTIONS_KEY];
      self[handlerMethodName] = event => {
        const target = event.target;
        const found = findNodeInEventPath(event, host);
        const excludedNodes = getExcludedNodes(opts);
        if (!isExcluded(target, excludedNodes) && !found) {
          method.call(self, event);
        }
      };
      subscriptions[CLICK_OUTSIDE_ENABLE_SUBJECT_KEY] = opts.enabled$(self).subscribe(enabled => {
        enabled ? addListeners(self) : removeListeners(self);
      });
    }
    proto.componentDidLoad = function () {
      init(this);
      return componentDidLoad && componentDidLoad.call(this);
    };
    proto.connectedCallback = function () {
      init(this);
      return connectedCallback && connectedCallback.call(this);
    };
    proto.disconnectedCallback = function () {
      const host = getElement(this);
      const subscriptions = host === null || host === void 0 ? void 0 : host[SUBSCRIPTIONS_KEY];
      subscriptions && subscriptions instanceof SubscriptionList && subscriptions.unsubscribeSafe(CLICK_OUTSIDE_ENABLE_SUBJECT_KEY);
      removeListeners(this);
      return disconnectedCallback && disconnectedCallback.call(this);
    };
  };
}
export { ClickOutside as C };