import * as i0 from '@angular/core';
import { forwardRef, Directive, HostListener, EventEmitter, Component, ChangeDetectionStrategy, NgModule } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { __decorate } from 'tslib';
import { OWC_GLOBAL_KEY, RUNTIME_FWK_GLOBAL_KEY, RUNTIME_FWK_VERSION_GLOBAL_KEY } from '@one/web-components';
import { defineCustomElement as defineCustomElement$1 } from '@one/web-components/components/owc-card.js';
import { defineCustomElement as defineCustomElement$2 } from '@one/web-components/components/owc-component-footer.js';
import { defineCustomElement as defineCustomElement$3 } from '@one/web-components/components/owc-component-header.js';
import { defineCustomElement as defineCustomElement$4 } from '@one/web-components/components/owc-contact-info.js';
import { defineCustomElement as defineCustomElement$5 } from '@one/web-components/components/owc-divider.js';
import { defineCustomElement as defineCustomElement$6 } from '@one/web-components/components/owc-expandable.js';
import { defineCustomElement as defineCustomElement$7 } from '@one/web-components/components/owc-expandable-group.js';
import { defineCustomElement as defineCustomElement$8 } from '@one/web-components/components/owc-footer.js';
import { defineCustomElement as defineCustomElement$9 } from '@one/web-components/components/owc-grid.js';
import { defineCustomElement as defineCustomElement$a } from '@one/web-components/components/owc-grid-item.js';
import { defineCustomElement as defineCustomElement$b } from '@one/web-components/components/owc-icon-provider.js';
import { defineCustomElement as defineCustomElement$c } from '@one/web-components/components/owc-list.js';
import { defineCustomElement as defineCustomElement$d } from '@one/web-components/components/owc-list-item.js';
import { defineCustomElement as defineCustomElement$e } from '@one/web-components/components/owc-popover.js';
import { defineCustomElement as defineCustomElement$f } from '@one/web-components/components/owc-side-menu.js';
import { defineCustomElement as defineCustomElement$g } from '@one/web-components/components/owc-split-screen.js';
import { defineCustomElement as defineCustomElement$h } from '@one/web-components/components/owc-tab.js';
import { defineCustomElement as defineCustomElement$i } from '@one/web-components/components/owc-tabs.js';
import { defineCustomElement as defineCustomElement$j } from '@one/web-components/components/owc-theme-provider.js';
const _c0 = ["*"];
class OwcExpandableAccessor {
  constructor(_renderer, el) {
    this._renderer = _renderer;
    this.el = el;
    this.onChange = () => {};
    this.onTouched = () => {};
  }
  setProperty(key, value) {
    this._renderer.setProperty(this.el.nativeElement, key, value);
  }
  getNormalizedValue(value) {
    return value == null ? undefined : value;
  }
  writeValue(value) {
    // The value needs to be normalized for IE9, otherwise it is set to 'null' when null
    const normalizedValue = this.getNormalizedValue(value);
    this.lastValue = normalizedValue;
    this.setProperty('expanded', normalizedValue);
  }
  handleChangeEvent(value) {
    const normalizedValue = this.getNormalizedValue(value);
    if (JSON.stringify(normalizedValue) !== JSON.stringify(this.lastValue)) {
      this.lastValue = normalizedValue;
      this.onChange(normalizedValue);
    }
  }
  _handleBlurEvent() {
    this.onTouched();
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled) {
    this.setProperty('disabled', isDisabled);
  }
}
OwcExpandableAccessor.ɵfac = function OwcExpandableAccessor_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcExpandableAccessor)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef));
};
OwcExpandableAccessor.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: OwcExpandableAccessor,
  selectors: [["owc-expandable"]],
  hostBindings: function OwcExpandableAccessor_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("expandedChange", function OwcExpandableAccessor_expandedChange_HostBindingHandler($event) {
        return ctx.handleChangeEvent($event.detail);
      })("focusout", function OwcExpandableAccessor_focusout_HostBindingHandler() {
        return ctx._handleBlurEvent();
      });
    }
  },
  standalone: false,
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => OwcExpandableAccessor),
    multi: true
  }])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcExpandableAccessor, [{
    type: Directive,
    args: [{
      selector: 'owc-expandable',
      host: {
        '(expandedChange)': 'handleChangeEvent($event.detail)'
      },
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => OwcExpandableAccessor),
        multi: true
      }]
    }]
  }], function () {
    return [{
      type: i0.Renderer2
    }, {
      type: i0.ElementRef
    }];
  }, {
    _handleBlurEvent: [{
      type: HostListener,
      args: ['focusout']
    }]
  });
})();
class OwcListAccessor {
  constructor(_renderer, el) {
    this._renderer = _renderer;
    this.el = el;
    this.onChange = () => {};
    this.onTouched = () => {};
  }
  setProperty(key, value) {
    this._renderer.setProperty(this.el.nativeElement, key, value);
  }
  getNormalizedValue(value) {
    return value == null ? undefined : value;
  }
  writeValue(value) {
    // The value needs to be normalized for IE9, otherwise it is set to 'null' when null
    const normalizedValue = this.getNormalizedValue(value);
    this.lastValue = normalizedValue;
    this.setProperty('selected', normalizedValue);
  }
  handleChangeEvent(value) {
    const normalizedValue = this.getNormalizedValue(value);
    if (JSON.stringify(normalizedValue) !== JSON.stringify(this.lastValue)) {
      this.lastValue = normalizedValue;
      this.onChange(normalizedValue);
    }
  }
  _handleBlurEvent() {
    this.onTouched();
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled) {
    this.setProperty('disabled', isDisabled);
  }
}
OwcListAccessor.ɵfac = function OwcListAccessor_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcListAccessor)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef));
};
OwcListAccessor.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: OwcListAccessor,
  selectors: [["owc-list"]],
  hostBindings: function OwcListAccessor_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("selectedChange", function OwcListAccessor_selectedChange_HostBindingHandler($event) {
        return ctx.handleChangeEvent($event.detail);
      })("focusout", function OwcListAccessor_focusout_HostBindingHandler() {
        return ctx._handleBlurEvent();
      });
    }
  },
  standalone: false,
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => OwcListAccessor),
    multi: true
  }])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcListAccessor, [{
    type: Directive,
    args: [{
      selector: 'owc-list',
      host: {
        '(selectedChange)': 'handleChangeEvent($event.detail)'
      },
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => OwcListAccessor),
        multi: true
      }]
    }]
  }], function () {
    return [{
      type: i0.Renderer2
    }, {
      type: i0.ElementRef
    }];
  }, {
    _handleBlurEvent: [{
      type: HostListener,
      args: ['focusout']
    }]
  });
})();
class OwcSplitScreenAccessor {
  constructor(_renderer, el) {
    this._renderer = _renderer;
    this.el = el;
    this.onChange = () => {};
    this.onTouched = () => {};
  }
  setProperty(key, value) {
    this._renderer.setProperty(this.el.nativeElement, key, value);
  }
  getNormalizedValue(value) {
    return value == null ? undefined : value;
  }
  writeValue(value) {
    // The value needs to be normalized for IE9, otherwise it is set to 'null' when null
    const normalizedValue = this.getNormalizedValue(value);
    this.lastValue = normalizedValue;
    this.setProperty('size', normalizedValue);
  }
  handleChangeEvent(value) {
    const normalizedValue = this.getNormalizedValue(value);
    if (JSON.stringify(normalizedValue) !== JSON.stringify(this.lastValue)) {
      this.lastValue = normalizedValue;
      this.onChange(normalizedValue);
    }
  }
  _handleBlurEvent() {
    this.onTouched();
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled) {
    this.setProperty('disabled', isDisabled);
  }
}
OwcSplitScreenAccessor.ɵfac = function OwcSplitScreenAccessor_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcSplitScreenAccessor)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef));
};
OwcSplitScreenAccessor.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: OwcSplitScreenAccessor,
  selectors: [["owc-split-screen"]],
  hostBindings: function OwcSplitScreenAccessor_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("sizeChange", function OwcSplitScreenAccessor_sizeChange_HostBindingHandler($event) {
        return ctx.handleChangeEvent($event.detail);
      })("focusout", function OwcSplitScreenAccessor_focusout_HostBindingHandler() {
        return ctx._handleBlurEvent();
      });
    }
  },
  standalone: false,
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => OwcSplitScreenAccessor),
    multi: true
  }])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcSplitScreenAccessor, [{
    type: Directive,
    args: [{
      selector: 'owc-split-screen',
      host: {
        '(sizeChange)': 'handleChangeEvent($event.detail)'
      },
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => OwcSplitScreenAccessor),
        multi: true
      }]
    }]
  }], function () {
    return [{
      type: i0.Renderer2
    }, {
      type: i0.ElementRef
    }];
  }, {
    _handleBlurEvent: [{
      type: HostListener,
      args: ['focusout']
    }]
  });
})();
class OwcTabAccessor {
  constructor(_renderer, el) {
    this._renderer = _renderer;
    this.el = el;
    this.onChange = () => {};
    this.onTouched = () => {};
  }
  setProperty(key, value) {
    this._renderer.setProperty(this.el.nativeElement, key, value);
  }
  getNormalizedValue(value) {
    return value == null ? undefined : value;
  }
  writeValue(value) {
    // The value needs to be normalized for IE9, otherwise it is set to 'null' when null
    const normalizedValue = this.getNormalizedValue(value);
    this.lastValue = normalizedValue;
    this.setProperty('selected', normalizedValue);
  }
  handleChangeEvent(value) {
    const normalizedValue = this.getNormalizedValue(value);
    if (JSON.stringify(normalizedValue) !== JSON.stringify(this.lastValue)) {
      this.lastValue = normalizedValue;
      this.onChange(normalizedValue);
    }
  }
  _handleBlurEvent() {
    this.onTouched();
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled) {
    this.setProperty('disabled', isDisabled);
  }
}
OwcTabAccessor.ɵfac = function OwcTabAccessor_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcTabAccessor)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef));
};
OwcTabAccessor.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: OwcTabAccessor,
  selectors: [["owc-tab"]],
  hostBindings: function OwcTabAccessor_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("selectedChange", function OwcTabAccessor_selectedChange_HostBindingHandler($event) {
        return ctx.handleChangeEvent($event.detail);
      })("focusout", function OwcTabAccessor_focusout_HostBindingHandler() {
        return ctx._handleBlurEvent();
      });
    }
  },
  standalone: false,
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => OwcTabAccessor),
    multi: true
  }])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcTabAccessor, [{
    type: Directive,
    args: [{
      selector: 'owc-tab',
      host: {
        '(selectedChange)': 'handleChangeEvent($event.detail)'
      },
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => OwcTabAccessor),
        multi: true
      }]
    }]
  }], function () {
    return [{
      type: i0.Renderer2
    }, {
      type: i0.ElementRef
    }];
  }, {
    _handleBlurEvent: [{
      type: HostListener,
      args: ['focusout']
    }]
  });
})();
class OwcTabsAccessor {
  constructor(_renderer, el) {
    this._renderer = _renderer;
    this.el = el;
    this.onChange = () => {};
    this.onTouched = () => {};
  }
  setProperty(key, value) {
    this._renderer.setProperty(this.el.nativeElement, key, value);
  }
  getNormalizedValue(value) {
    return value == null ? undefined : value;
  }
  writeValue(value) {
    // The value needs to be normalized for IE9, otherwise it is set to 'null' when null
    const normalizedValue = this.getNormalizedValue(value);
    this.lastValue = normalizedValue;
    this.setProperty('value', normalizedValue);
  }
  handleChangeEvent(value) {
    const normalizedValue = this.getNormalizedValue(value);
    if (JSON.stringify(normalizedValue) !== JSON.stringify(this.lastValue)) {
      this.lastValue = normalizedValue;
      this.onChange(normalizedValue);
    }
  }
  _handleBlurEvent() {
    this.onTouched();
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled) {
    this.setProperty('disabled', isDisabled);
  }
}
OwcTabsAccessor.ɵfac = function OwcTabsAccessor_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcTabsAccessor)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef));
};
OwcTabsAccessor.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: OwcTabsAccessor,
  selectors: [["owc-tabs"]],
  hostBindings: function OwcTabsAccessor_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("valueChange", function OwcTabsAccessor_valueChange_HostBindingHandler($event) {
        return ctx.handleChangeEvent($event.detail);
      })("focusout", function OwcTabsAccessor_focusout_HostBindingHandler() {
        return ctx._handleBlurEvent();
      });
    }
  },
  standalone: false,
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => OwcTabsAccessor),
    multi: true
  }])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcTabsAccessor, [{
    type: Directive,
    args: [{
      selector: 'owc-tabs',
      host: {
        '(valueChange)': 'handleChangeEvent($event.detail)'
      },
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => OwcTabsAccessor),
        multi: true
      }]
    }]
  }], function () {
    return [{
      type: i0.Renderer2
    }, {
      type: i0.ElementRef
    }];
  }, {
    _handleBlurEvent: [{
      type: HostListener,
      args: ['focusout']
    }]
  });
})();
const DIRECTIVES = [OwcExpandableAccessor, OwcListAccessor, OwcSplitScreenAccessor, OwcTabAccessor, OwcTabsAccessor];
var fwk_version = "7.1.0";
var buildMetadata = {
  fwk_version: fwk_version
};

/* eslint-disable */
const fixNestedFunctionCalls = (val, self) => {
  if (val instanceof Element || val instanceof Document) {
    return val;
  } else if (Array.isArray(val)) {
    return val.map(v => fixNestedFunctionCalls(v, self));
  } else if (val === null || val === undefined) {
    return val;
  } else if (val instanceof Date) {
    return new Date(val.getTime());
  } else if (typeof val === 'object') {
    const fixedObj = {};
    for (const key of Object.keys(val)) {
      fixedObj[key] = fixNestedFunctionCalls(val[key], self);
    }
    return fixedObj;
  } else if (typeof val === 'function') {
    return (...args) => self.z.runTask(() => val(...args));
  } else {
    return val;
  }
};
const proxyInputs = (Cmp, inputs) => {
  const Prototype = Cmp.prototype;
  inputs.forEach(item => {
    Object.defineProperty(Prototype, item, {
      get() {
        return this.el[item];
      },
      set(val) {
        this.z.runOutsideAngular(() => this.el[item] = fixNestedFunctionCalls(val, this));
      }
    });
  });
};
const proxyMethods = (Cmp, methods) => {
  const Prototype = Cmp.prototype;
  methods.forEach(methodName => {
    Prototype[methodName] = function () {
      const args = arguments;
      return this.z.runOutsideAngular(() => this.el[methodName].apply(this.el, args));
    };
  });
};
const proxyOutputs = (instance, el, events) => {
  //   events.forEach(eventName => (instance[eventName] = fromEvent(el, eventName)));
  events.forEach(eventName => instance[eventName] = new EventEmitter());
};
const defineCustomElement = (tagName, customElement) => {
  if (customElement !== undefined && typeof customElements !== 'undefined' && !customElements.get(tagName)) {
    customElements.define(tagName, customElement);
  }
};
// tslint:disable-next-line: only-arrow-functions
function ProxyCmp(opts) {
  const decorator = function (cls) {
    const {
      defineCustomElementFn,
      inputs,
      methods
    } = opts;
    if (defineCustomElementFn !== undefined) {
      defineCustomElementFn();
    }
    if (typeof window !== 'undefined' && OWC_GLOBAL_KEY in window) {
      if (!(RUNTIME_FWK_GLOBAL_KEY in window[OWC_GLOBAL_KEY])) {
        Object.defineProperty(window[OWC_GLOBAL_KEY], RUNTIME_FWK_GLOBAL_KEY, {
          configurable: true,
          enumerable: true,
          writable: true,
          value: 'angular'
        });
      }
      if (!(RUNTIME_FWK_VERSION_GLOBAL_KEY in window[OWC_GLOBAL_KEY])) {
        Object.defineProperty(window[OWC_GLOBAL_KEY], RUNTIME_FWK_VERSION_GLOBAL_KEY, {
          configurable: true,
          enumerable: true,
          writable: true,
          value: fwk_version || 'N/A'
        });
      }
    }
    if (inputs) {
      proxyInputs(cls, inputs);
    }
    if (methods) {
      proxyMethods(cls, methods);
    }
    return cls;
  };
  return decorator;
}
defineCustomElement$1();
let OwcCard = class OwcCard {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcCard.ɵfac = function OwcCard_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcCard)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcCard.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcCard,
  selectors: [["owc-card"]],
  inputs: {
    contentWrapperProps: "contentWrapperProps",
    disabled: "disabled",
    dragging: "dragging",
    elevated: "elevated",
    interactive: "interactive",
    rounded: "rounded",
    selected: "selected"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcCard_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcCard = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$1,
  inputs: ['contentWrapperProps', 'disabled', 'dragging', 'elevated', 'interactive', 'rounded', 'selected']
})], OwcCard);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcCard, [{
    type: Component,
    args: [{
      selector: 'owc-card',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['contentWrapperProps', 'disabled', 'dragging', 'elevated', 'interactive', 'rounded', 'selected']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
defineCustomElement$2();
let OwcComponentFooter = class OwcComponentFooter {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcComponentFooter.ɵfac = function OwcComponentFooter_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcComponentFooter)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcComponentFooter.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcComponentFooter,
  selectors: [["owc-component-footer"]],
  inputs: {
    separator: "separator"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcComponentFooter_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcComponentFooter = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$2,
  inputs: ['separator']
})], OwcComponentFooter);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcComponentFooter, [{
    type: Component,
    args: [{
      selector: 'owc-component-footer',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['separator']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
defineCustomElement$3();
let OwcComponentHeader = class OwcComponentHeader {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcComponentHeader.ɵfac = function OwcComponentHeader_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcComponentHeader)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcComponentHeader.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcComponentHeader,
  selectors: [["owc-component-header"]],
  inputs: {
    separator: "separator",
    status: "status"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcComponentHeader_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcComponentHeader = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$3,
  inputs: ['separator', 'status']
})], OwcComponentHeader);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcComponentHeader, [{
    type: Component,
    args: [{
      selector: 'owc-component-header',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['separator', 'status']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
defineCustomElement$4();
let OwcContactInfo = class OwcContactInfo {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcContactInfo.ɵfac = function OwcContactInfo_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcContactInfo)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcContactInfo.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcContactInfo,
  selectors: [["owc-contact-info"]],
  inputs: {
    type: "type"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcContactInfo_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcContactInfo = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$4,
  inputs: ['type']
})], OwcContactInfo);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcContactInfo, [{
    type: Component,
    args: [{
      selector: 'owc-contact-info',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['type']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
defineCustomElement$5();
let OwcDivider = class OwcDivider {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcDivider.ɵfac = function OwcDivider_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcDivider)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcDivider.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcDivider,
  selectors: [["owc-divider"]],
  inputs: {
    captionPosition: "captionPosition",
    direction: "direction",
    type: "type"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcDivider_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcDivider = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$5,
  inputs: ['captionPosition', 'direction', 'type']
})], OwcDivider);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcDivider, [{
    type: Component,
    args: [{
      selector: 'owc-divider',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['captionPosition', 'direction', 'type']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
defineCustomElement$6();
let OwcExpandable = class OwcExpandable {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['expandedChange']);
  }
};
OwcExpandable.ɵfac = function OwcExpandable_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcExpandable)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcExpandable.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcExpandable,
  selectors: [["owc-expandable"]],
  inputs: {
    controlPlacement: "controlPlacement",
    disabled: "disabled",
    expanded: "expanded",
    tooltipProps: "tooltipProps",
    variant: "variant"
  },
  outputs: {
    expandedChange: "expandedChange"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcExpandable_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcExpandable = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$6,
  inputs: ['controlPlacement', 'disabled', 'expanded', 'tooltipProps', 'variant']
})], OwcExpandable);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcExpandable, [{
    type: Component,
    args: [{
      selector: 'owc-expandable',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['controlPlacement', 'disabled', 'expanded', 'tooltipProps', 'variant'],
      outputs: ['expandedChange']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
defineCustomElement$7();
let OwcExpandableGroup = class OwcExpandableGroup {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['expandedElementsChanged']);
  }
};
OwcExpandableGroup.ɵfac = function OwcExpandableGroup_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcExpandableGroup)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcExpandableGroup.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcExpandableGroup,
  selectors: [["owc-expandable-group"]],
  inputs: {
    multiple: "multiple"
  },
  outputs: {
    expandedElementsChanged: "expandedElementsChanged"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcExpandableGroup_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcExpandableGroup = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$7,
  inputs: ['multiple'],
  methods: ['setAllState']
})], OwcExpandableGroup);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcExpandableGroup, [{
    type: Component,
    args: [{
      selector: 'owc-expandable-group',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['multiple'],
      outputs: ['expandedElementsChanged']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
defineCustomElement$8();
let OwcFooter = class OwcFooter {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcFooter.ɵfac = function OwcFooter_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcFooter)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcFooter.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcFooter,
  selectors: [["owc-footer"]],
  inputs: {
    fixBottom: "fixBottom",
    variant: "variant"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcFooter_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcFooter = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$8,
  inputs: ['fixBottom', 'variant']
})], OwcFooter);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcFooter, [{
    type: Component,
    args: [{
      selector: 'owc-footer',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['fixBottom', 'variant']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
defineCustomElement$9();
let OwcGrid = class OwcGrid {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcGrid.ɵfac = function OwcGrid_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcGrid)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcGrid.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcGrid,
  selectors: [["owc-grid"]],
  inputs: {
    columns: "columns",
    horizontalSpacing: "horizontalSpacing",
    margin: "margin",
    spacing: "spacing",
    verticalSpacing: "verticalSpacing"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcGrid_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcGrid = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$9,
  inputs: ['columns', 'horizontalSpacing', 'margin', 'spacing', 'verticalSpacing']
})], OwcGrid);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcGrid, [{
    type: Component,
    args: [{
      selector: 'owc-grid',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['columns', 'horizontalSpacing', 'margin', 'spacing', 'verticalSpacing']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
defineCustomElement$a();
let OwcGridItem = class OwcGridItem {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcGridItem.ɵfac = function OwcGridItem_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcGridItem)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcGridItem.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcGridItem,
  selectors: [["owc-grid-item"]],
  inputs: {
    horizontalPlacement: "horizontalPlacement",
    lg: "lg",
    md: "md",
    padding: "padding",
    sm: "sm",
    verticalPlacement: "verticalPlacement",
    xl: "xl",
    xs: "xs"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcGridItem_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcGridItem = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$a,
  inputs: ['horizontalPlacement', 'lg', 'md', 'padding', 'sm', 'verticalPlacement', 'xl', 'xs']
})], OwcGridItem);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcGridItem, [{
    type: Component,
    args: [{
      selector: 'owc-grid-item',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['horizontalPlacement', 'lg', 'md', 'padding', 'sm', 'verticalPlacement', 'xl', 'xs']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
defineCustomElement$b();
let OwcIconProvider = class OwcIconProvider {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcIconProvider.ɵfac = function OwcIconProvider_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcIconProvider)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcIconProvider.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcIconProvider,
  selectors: [["owc-icon-provider"]],
  inputs: {
    base: "base",
    family: "family"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcIconProvider_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcIconProvider = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$b,
  inputs: ['base', 'family']
})], OwcIconProvider);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcIconProvider, [{
    type: Component,
    args: [{
      selector: 'owc-icon-provider',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['base', 'family']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
defineCustomElement$c();
let OwcList = class OwcList {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['focusChange', 'selectedChange']);
  }
};
OwcList.ɵfac = function OwcList_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcList)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcList.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcList,
  selectors: [["owc-list"]],
  inputs: {
    hostId: "hostId",
    hostRole: "hostRole",
    multiple: "multiple",
    noBorder: "noBorder",
    noDefaultBlurHandler: "noDefaultBlurHandler",
    notFocusable: "notFocusable",
    notTabbable: "notTabbable",
    parentSize: "parentSize",
    position: "position",
    selected: "selected",
    showSelectorControl: "showSelectorControl",
    size: "size",
    variant: "variant"
  },
  outputs: {
    focusChange: "focusChange",
    selectedChange: "selectedChange"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcList_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcList = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$c,
  inputs: ['hostId', 'hostRole', 'multiple', 'noBorder', 'noDefaultBlurHandler', 'notFocusable', 'notTabbable', 'parentSize', 'position', 'selected', 'showSelectorControl', 'size', 'variant'],
  methods: ['getKeydownManager', 'getSelectedItemsTextContent']
})], OwcList);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcList, [{
    type: Component,
    args: [{
      selector: 'owc-list',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['hostId', 'hostRole', 'multiple', 'noBorder', 'noDefaultBlurHandler', 'notFocusable', 'notTabbable', 'parentSize', 'position', 'selected', 'showSelectorControl', 'size', 'variant'],
      outputs: ['focusChange', 'selectedChange']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
defineCustomElement$d();
let OwcListItem = class OwcListItem {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['expandedChange']);
  }
};
OwcListItem.ɵfac = function OwcListItem_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcListItem)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcListItem.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcListItem,
  selectors: [["owc-list-item"]],
  inputs: {
    checkboxState: "checkboxState",
    disableRipple: "disableRipple",
    disabled: "disabled",
    expanded: "expanded",
    expanderIconProps: "expanderIconProps",
    hostHidden: "hostHidden",
    hostId: "hostId",
    hostRole: "hostRole",
    leaf: "leaf",
    managedCheckboxState: "managedCheckboxState",
    menuItem: "menuItem",
    menuVisible: "menuVisible",
    multiple: "multiple",
    noBorder: "noBorder",
    noExpandIcon: "noExpandIcon",
    notFocusable: "notFocusable",
    parentCollapsed: "parentCollapsed",
    parentDisabled: "parentDisabled",
    parentSize: "parentSize",
    position: "position",
    searchKey: "searchKey",
    selectable: "selectable",
    selected: "selected",
    showSelectorControl: "showSelectorControl",
    size: "size",
    tabbable: "tabbable",
    tooltipProps: "tooltipProps",
    value: "value",
    variant: "variant"
  },
  outputs: {
    expandedChange: "expandedChange"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcListItem_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcListItem = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$d,
  inputs: ['checkboxState', 'disableRipple', 'disabled', 'expanded', 'expanderIconProps', 'hostHidden', 'hostId', 'hostRole', 'leaf', 'managedCheckboxState', 'menuItem', 'menuVisible', 'multiple', 'noBorder', 'noExpandIcon', 'notFocusable', 'parentCollapsed', 'parentDisabled', 'parentSize', 'position', 'searchKey', 'selectable', 'selected', 'showSelectorControl', 'size', 'tabbable', 'tooltipProps', 'value', 'variant'],
  methods: ['getIndentationLevel', 'getTextContent', 'updateInternalStates']
})], OwcListItem);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcListItem, [{
    type: Component,
    args: [{
      selector: 'owc-list-item',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['checkboxState', 'disableRipple', 'disabled', 'expanded', 'expanderIconProps', 'hostHidden', 'hostId', 'hostRole', 'leaf', 'managedCheckboxState', 'menuItem', 'menuVisible', 'multiple', 'noBorder', 'noExpandIcon', 'notFocusable', 'parentCollapsed', 'parentDisabled', 'parentSize', 'position', 'searchKey', 'selectable', 'selected', 'showSelectorControl', 'size', 'tabbable', 'tooltipProps', 'value', 'variant'],
      outputs: ['expandedChange']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
defineCustomElement$e();
let OwcPopover = class OwcPopover {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['clickOutside', 'visibleChange']);
  }
};
OwcPopover.ɵfac = function OwcPopover_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcPopover)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcPopover.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcPopover,
  selectors: [["owc-popover"]],
  inputs: {
    anchor: "anchor",
    disableAccessibility: "disableAccessibility",
    hostId: "hostId",
    offset: "offset",
    position: "position",
    preventOverflow: "preventOverflow",
    strategy: "strategy",
    trigger: "trigger",
    visible: "visible"
  },
  outputs: {
    clickOutside: "clickOutside",
    visibleChange: "visibleChange"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcPopover_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcPopover = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$e,
  inputs: ['anchor', 'disableAccessibility', 'hostId', 'offset', 'position', 'preventOverflow', 'strategy', 'trigger', 'visible'],
  methods: ['forceUpdate']
})], OwcPopover);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcPopover, [{
    type: Component,
    args: [{
      selector: 'owc-popover',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['anchor', 'disableAccessibility', 'hostId', 'offset', 'position', 'preventOverflow', 'strategy', 'trigger', 'visible'],
      outputs: ['clickOutside', 'visibleChange']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
defineCustomElement$f();
let OwcSideMenu = class OwcSideMenu {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcSideMenu.ɵfac = function OwcSideMenu_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcSideMenu)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcSideMenu.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcSideMenu,
  selectors: [["owc-side-menu"]],
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcSideMenu_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcSideMenu = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$f,
  inputs: []
})], OwcSideMenu);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcSideMenu, [{
    type: Component,
    args: [{
      selector: 'owc-side-menu',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: []
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
defineCustomElement$g();
let OwcSplitScreen = class OwcSplitScreen {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['resizing', 'sizeChange']);
  }
};
OwcSplitScreen.ɵfac = function OwcSplitScreen_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcSplitScreen)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcSplitScreen.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcSplitScreen,
  selectors: [["owc-split-screen"]],
  inputs: {
    direction: "direction",
    disabled: "disabled",
    hostAriaLabel: "hostAriaLabel",
    size: "size"
  },
  outputs: {
    resizing: "resizing",
    sizeChange: "sizeChange"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcSplitScreen_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcSplitScreen = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$g,
  inputs: ['direction', 'disabled', 'hostAriaLabel', 'size']
})], OwcSplitScreen);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcSplitScreen, [{
    type: Component,
    args: [{
      selector: 'owc-split-screen',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['direction', 'disabled', 'hostAriaLabel', 'size'],
      outputs: ['resizing', 'sizeChange']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
defineCustomElement$h();
let OwcTab = class OwcTab {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['selectedChange']);
  }
};
OwcTab.ɵfac = function OwcTab_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcTab)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcTab.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcTab,
  selectors: [["owc-tab"]],
  inputs: {
    configuration: "configuration",
    controlled: "controlled",
    direction: "direction",
    disableRipple: "disableRipple",
    disabled: "disabled",
    hostId: "hostId",
    selected: "selected",
    size: "size",
    value: "value",
    variant: "variant"
  },
  outputs: {
    selectedChange: "selectedChange"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcTab_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcTab = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$h,
  inputs: ['configuration', 'controlled', 'direction', 'disableRipple', 'disabled', 'hostId', 'selected', 'size', 'value', 'variant']
})], OwcTab);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcTab, [{
    type: Component,
    args: [{
      selector: 'owc-tab',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['configuration', 'controlled', 'direction', 'disableRipple', 'disabled', 'hostId', 'selected', 'size', 'value', 'variant'],
      outputs: ['selectedChange']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
defineCustomElement$i();
let OwcTabs = class OwcTabs {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['valueChange']);
  }
};
OwcTabs.ɵfac = function OwcTabs_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcTabs)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcTabs.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcTabs,
  selectors: [["owc-tabs"]],
  inputs: {
    configuration: "configuration",
    controlled: "controlled",
    direction: "direction",
    disableRipple: "disableRipple",
    divider: "divider",
    elevated: "elevated",
    size: "size",
    value: "value",
    variant: "variant"
  },
  outputs: {
    valueChange: "valueChange"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcTabs_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcTabs = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$i,
  inputs: ['configuration', 'controlled', 'direction', 'disableRipple', 'divider', 'elevated', 'size', 'value', 'variant']
})], OwcTabs);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcTabs, [{
    type: Component,
    args: [{
      selector: 'owc-tabs',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['configuration', 'controlled', 'direction', 'disableRipple', 'divider', 'elevated', 'size', 'value', 'variant'],
      outputs: ['valueChange']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
defineCustomElement$j();
let OwcThemeProvider = class OwcThemeProvider {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
OwcThemeProvider.ɵfac = function OwcThemeProvider_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || OwcThemeProvider)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
};
OwcThemeProvider.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OwcThemeProvider,
  selectors: [["owc-theme-provider"]],
  inputs: {
    customTheme: "customTheme",
    targetElement: "targetElement",
    theme: "theme"
  },
  standalone: false,
  ngContentSelectors: _c0,
  decls: 1,
  vars: 0,
  template: function OwcThemeProvider_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
OwcThemeProvider = __decorate([ProxyCmp({
  defineCustomElementFn: defineCustomElement$j,
  inputs: ['customTheme', 'targetElement', 'theme']
})], OwcThemeProvider);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OwcThemeProvider, [{
    type: Component,
    args: [{
      selector: 'owc-theme-provider',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      inputs: ['customTheme', 'targetElement', 'theme']
    }]
  }], function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }, null);
})();
const COMPONENTS = [OwcCard, OwcComponentFooter, OwcComponentHeader, OwcContactInfo, OwcDivider, OwcExpandable, OwcExpandableGroup, OwcFooter, OwcGrid, OwcGridItem, OwcIconProvider, OwcList, OwcListItem, OwcPopover, OwcSideMenu, OwcSplitScreen, OwcTab, OwcTabs, OwcThemeProvider];
class LayoutModule {}
LayoutModule.ɵfac = function LayoutModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || LayoutModule)();
};
LayoutModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: LayoutModule
});
LayoutModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LayoutModule, [{
    type: NgModule,
    args: [{
      declarations: [...COMPONENTS, ...DIRECTIVES],
      imports: [],
      exports: [...COMPONENTS, ...DIRECTIVES]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { DIRECTIVES, LayoutModule, OwcCard, OwcComponentFooter, OwcComponentHeader, OwcContactInfo, OwcDivider, OwcExpandable, OwcExpandableAccessor, OwcExpandableGroup, OwcFooter, OwcGrid, OwcGridItem, OwcIconProvider, OwcList, OwcListAccessor, OwcListItem, OwcPopover, OwcSideMenu, OwcSplitScreen, OwcSplitScreenAccessor, OwcTab, OwcTabAccessor, OwcTabs, OwcTabsAccessor, OwcThemeProvider };
