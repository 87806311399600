import { c as MutationObserverSafe } from './p-a57042e3.js';
var __rest = undefined && undefined.__rest || function (s, e) {
  var t = {};
  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};
function OnElementMutation(opts) {
  return (proto, methodName) => {
    const {
      componentDidLoad,
      disconnectedCallback,
      connectedCallback
    } = proto;
    const observerAttrName = `${methodName}-observer`;
    const observe = (self, _a) => {
      var _b;
      var {
          target
        } = _a,
        props = __rest(_a, ["target"]);
      const el = target(self);
      if (el) {
        (_b = self[observerAttrName]) === null || _b === void 0 ? void 0 : _b.disconnect();
        const method = self[methodName];
        self[observerAttrName] = new MutationObserverSafe(method.bind(self));
        self[observerAttrName].observe(el, props);
      }
    };
    proto.componentDidLoad = function () {
      observe(this, opts);
      return componentDidLoad === null || componentDidLoad === void 0 ? void 0 : componentDidLoad.call(this);
    };
    proto.connectedCallback = function () {
      observe(this, opts);
      return connectedCallback === null || connectedCallback === void 0 ? void 0 : connectedCallback.call(this);
    };
    proto.disconnectedCallback = function () {
      var _a;
      (_a = this[observerAttrName]) === null || _a === void 0 ? void 0 : _a.disconnect();
      return disconnectedCallback === null || disconnectedCallback === void 0 ? void 0 : disconnectedCallback.call(this);
    };
  };
}
export { OnElementMutation as O };