import { p as proxyCustomElement, H, h, c as Host } from './p-92c4a1ea.js';
import './p-a57042e3.js';
import { a as Context, C as ContextValue } from './p-f5bb9925.js';
import { a as setTheme } from './p-b04c4d1f.js';
import { C as CTX_KEYS } from './p-16b4fd0e.js';
const themeProviderCss = ".sc-owc-theme-provider-h{display:contents}";
const OwcThemeProviderStyle0 = themeProviderCss;
var __decorate = undefined && undefined.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
const ThemeProvider = /*@__PURE__*/proxyCustomElement(class ThemeProvider extends H {
  constructor() {
    super();
    this.__registerHost();
    this.ctxTheme = new ContextValue();
    this.updateTheme = () => {
      setTheme(this.theme, this.customTheme, this.targetElement);
      this.ctxTheme.set(this.theme);
    };
    this.theme = 'regular_navify_light';
    this.customTheme = {};
    this.targetElement = this.hostElement;
  }
  onThemeChange() {
    this.updateTheme();
  }
  componentDidLoad() {
    this.updateTheme();
  }
  render() {
    return h(Host, {
      key: 'b494180acb65ca5e31196f004d460242d08b6e31'
    }, h("slot", {
      key: '0acb5214e7a7529d122aed3402cdba07f7470d91'
    }));
  }
  get hostElement() {
    return this;
  }
  static get watchers() {
    return {
      "theme": ["onThemeChange"],
      "customTheme": ["onThemeChange"],
      "targetElement": ["onThemeChange"]
    };
  }
  static get style() {
    return OwcThemeProviderStyle0;
  }
}, [6, "owc-theme-provider", {
  "theme": [513],
  "customTheme": [16],
  "targetElement": [16]
}, undefined, {
  "theme": ["onThemeChange"],
  "customTheme": ["onThemeChange"],
  "targetElement": ["onThemeChange"]
}]);
__decorate([Context(CTX_KEYS.THEME_PROVIDER.THEME)], ThemeProvider.prototype, "ctxTheme", void 0);
function defineCustomElement$1() {
  if (typeof customElements === "undefined") {
    return;
  }
  const components = ["owc-theme-provider"];
  components.forEach(tagName => {
    switch (tagName) {
      case "owc-theme-provider":
        if (!customElements.get(tagName)) {
          customElements.define(tagName, ThemeProvider);
        }
        break;
    }
  });
}
const OwcThemeProvider = ThemeProvider;
const defineCustomElement = defineCustomElement$1;
export { OwcThemeProvider, defineCustomElement };