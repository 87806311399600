import { au as getElement } from './p-a57042e3.js';
function OnClosestFormReset() {
  return (proto, methodName) => {
    const {
      disconnectedCallback,
      connectedCallback
    } = proto;
    const listenerMethodName = `${methodName}-listener`;
    function init(self) {
      const method = self[methodName];
      if (!self[listenerMethodName]) {
        self[listenerMethodName] = event => {
          method.call(self, event);
        };
      }
    }
    proto.connectedCallback = function () {
      init(this);
      const host = getElement(this);
      const form = host === null || host === void 0 ? void 0 : host.closest('form');
      form === null || form === void 0 ? void 0 : form.removeEventListener('reset', this[listenerMethodName]);
      form === null || form === void 0 ? void 0 : form.addEventListener('reset', this[listenerMethodName]);
      return connectedCallback === null || connectedCallback === void 0 ? void 0 : connectedCallback.call(this);
    };
    proto.disconnectedCallback = function () {
      const host = getElement(this);
      const form = host === null || host === void 0 ? void 0 : host.closest('form');
      form === null || form === void 0 ? void 0 : form.removeEventListener('reset', this[listenerMethodName].bind(this));
      return disconnectedCallback === null || disconnectedCallback === void 0 ? void 0 : disconnectedCallback.call(this);
    };
  };
}
export { OnClosestFormReset as O };