import { a7 as KeyboardCode, ac as KeydownManager, a8 as KeydownAction } from "./MutationObserverSafe-08b9629b.js";
var PAGE_JUMP_OFFSET = 10;
var generateDropdownKeydownManager = function (e, n, o) {
  return {
    targetLazy: function () {
      return n;
    },
    opts: {
      scroll: {
        container: function () {
          return o;
        }
      },
      keydownHandlers: [{
        key: [KeyboardCode.Tab, {
          code: KeyboardCode.Tab,
          shift: true
        }],
        handler: function () {
          o === null || o === void 0 ? void 0 : o.getKeydownManager().then(function (e) {
            e.clearFocus();
            e.setStartIndex(-1);
            e.scrollToFirstElement();
          });
        },
        preventDefault: false
      }, {
        key: KeydownManager.DefaultKeys[KeydownAction.CLOSE],
        disabled: function () {
          return !e.visible;
        },
        handler: function () {
          return e.visible = false;
        }
      }, {
        key: [KeyboardCode.ArrowDown],
        handler: function (n) {
          if (e.visible) {
            o === null || o === void 0 ? void 0 : o.dispatchEvent(new KeyboardEvent(n.event.type, n.event));
          } else {
            e.visible = true;
            o === null || o === void 0 ? void 0 : o.getKeydownManager().then(function (e) {
              e.clearFocus();
              e.setCurrentItem(e.getActualSelectedItem() || e.getFirstItem(), n, true);
            });
          }
        },
        preventDefault: false
      }, {
        key: [{
          code: KeyboardCode.ArrowDown,
          alt: true
        }],
        handler: function () {
          e.visible = true;
          o === null || o === void 0 ? void 0 : o.getKeydownManager().then(function (e) {
            e.clearFocus();
            e.setStartIndex(-1);
            e.scrollToFirstElement();
          });
        },
        disabled: function () {
          return e.visible;
        }
      }, {
        key: [KeyboardCode.ArrowUp],
        handler: function (n) {
          if (e.visible) {
            o === null || o === void 0 ? void 0 : o.dispatchEvent(new KeyboardEvent(n.event.type, n.event));
          } else {
            e.visible = true;
            o === null || o === void 0 ? void 0 : o.getKeydownManager().then(function (e) {
              return e.setCurrentItem(e.getLastItem(), n);
            });
          }
        }
      }, {
        key: [KeyboardCode.ArrowRight, KeyboardCode.ArrowLeft],
        handler: function (n) {
          if (e.visible) {
            o === null || o === void 0 ? void 0 : o.dispatchEvent(new KeyboardEvent(n.event.type, n.event));
          }
        },
        preventDefault: true,
        disabled: function () {
          return !e.visible;
        }
      }, {
        key: [KeyboardCode.Home],
        handler: function (n) {
          e.visible = true;
          o === null || o === void 0 ? void 0 : o.getKeydownManager().then(function (e) {
            return e.setCurrentItem(e.getFirstItem(), n);
          });
        }
      }, {
        key: [KeyboardCode.End],
        handler: function (n) {
          e.visible = true;
          o === null || o === void 0 ? void 0 : o.getKeydownManager().then(function (e) {
            return e.setCurrentItem(e.getLastItem(), n);
          });
        }
      }, {
        key: [KeyboardCode.PageUp],
        handler: function (e) {
          o === null || o === void 0 ? void 0 : o.getKeydownManager().then(function (n) {
            return n.setCurrentItem(n.getItem(n.getCurrentIndex() - PAGE_JUMP_OFFSET, false), e);
          });
        },
        disabled: function () {
          return !e.visible;
        }
      }, {
        key: [KeyboardCode.PageDown],
        handler: function (e) {
          o === null || o === void 0 ? void 0 : o.getKeydownManager().then(function (n) {
            return n.setCurrentItem(n.getItem(n.getCurrentIndex() + PAGE_JUMP_OFFSET, false), e);
          });
        },
        disabled: function () {
          return !e.visible;
        }
      }, {
        key: [KeyboardCode.Enter, KeyboardCode.NumpadEnter],
        handler: function (n) {
          if (e.visible) {
            o === null || o === void 0 ? void 0 : o.dispatchEvent(new KeyboardEvent(n.event.type, n.event));
            e.focus();
          } else {
            e.visible = true;
            o === null || o === void 0 ? void 0 : o.getKeydownManager().then(function (e) {
              e.clearFocus();
              e.setCurrentItem(e.getActualSelectedItem() || e.getFirstItem(), n, true);
            });
          }
        }
      }, {
        key: [KeyboardCode.Space],
        handler: function (n) {
          if (!e.visible) {
            e.visible = true;
            o === null || o === void 0 ? void 0 : o.getKeydownManager().then(function (e) {
              e.clearFocus();
              e.setCurrentItem(e.getActualSelectedItem() || e.getFirstItem(), n, true);
            });
          }
        },
        preventDefault: false
      }, {
        key: [{
          code: KeyboardCode.ArrowUp,
          alt: true
        }],
        handler: function (n) {
          o === null || o === void 0 ? void 0 : o.getKeydownManager().then(function (o) {
            o.DefaultKeydownHandler[KeydownAction.SELECT](n);
            e.focus();
          });
        },
        disabled: function () {
          return !e.visible;
        }
      }]
    }
  };
};
export { generateDropdownKeydownManager as g };