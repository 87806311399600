var HEX_FULL_LENGTH = 6;
var RGB_COMPONENTS = 3;
var MAX_HEX_VALUE = 255;
var ALPHA_INDEX = 3;
var RED_LUMINANCE_WEIGHT = .299;
var GREEN_LUMINANCE_WEIGHT = .587;
var BLUE_LUMINANCE_WEIGHT = .114;
var clamp = function (r, a, o) {
  if (a === void 0) {
    a = 0;
  }
  if (o === void 0) {
    o = 1;
  }
  return Math.min(Math.max(a, r), o);
};
var hexToRgb = function (r) {
  var a = r.slice(1);
  var o = new RegExp(".{1,".concat(a.length >= HEX_FULL_LENGTH ? 2 : 1, "}"), "g");
  var e = a.match(o);
  if (e && e[0].length === 1) {
    e = e.map(function (r) {
      return r + r;
    });
  }
  return e ? "rgb".concat(e.length === 4 ? "a" : "", "(").concat(e.map(function (r, a) {
    return a < RGB_COMPONENTS ? parseInt(r, 16) : Math.round(parseInt(r, 16) / MAX_HEX_VALUE * 1e3) / 1e3;
  }).join(", "), ")") : "";
};
var decomposeColor = function (r) {
  if (r.charAt(0) === "#") {
    return decomposeColor(hexToRgb(r));
  }
  var a = r.indexOf("(");
  var o = r.substring(0, a);
  var e = r.substring(a + 1, r.length - 1);
  var E = e.split(",");
  var n = E.map(function (r) {
    return parseFloat(r);
  });
  return {
    type: o,
    values: n
  };
};
var grayscale = function (r) {
  var a = decomposeColor(r).values,
    o = a[0],
    e = a[1],
    E = a[2];
  return Math.floor(o * RED_LUMINANCE_WEIGHT + e * GREEN_LUMINANCE_WEIGHT + E * BLUE_LUMINANCE_WEIGHT);
};
var alpha = function (r, a) {
  var o = decomposeColor(r);
  var e = clamp(a);
  o.type === "rgb" && (o.type += "a");
  o.values[ALPHA_INDEX] = e;
  return "".concat(o.type, "(").concat(o.values.join(", "), ")");
};
export { alpha as a, decomposeColor as d, grayscale as g, hexToRgb as h };