import { r as randomString } from './p-a57042e3.js';
class SubjectBase {
  constructor() {
    this.consumers = new Map();
  }
}
const CONSUMER_ID_LENGTH = 32;
class BehaviorSubject extends SubjectBase {
  constructor(value = undefined) {
    super();
    this.value = value;
  }
  next(v, publishEvenForSameValue = false) {
    let shouldPublish = true;
    const oldValue = this.value;
    if (!publishEvenForSameValue) {
      try {
        shouldPublish = JSON.stringify(v) !== JSON.stringify(this.value);
      } catch (e) {
        console.warn('Error occurred during publishing new value', e);
      }
    }
    if (shouldPublish) {
      this.value = v;
      this.consumers.forEach(consumer => consumer === null || consumer === void 0 ? void 0 : consumer(v, oldValue));
    }
  }
  subscribe(cb) {
    const consumerId = randomString(CONSUMER_ID_LENGTH);
    this.consumers.set(consumerId, cb);
    cb === null || cb === void 0 ? void 0 : cb(this.value);
    return {
      id: consumerId,
      unsubscribe: () => this.consumers.delete(consumerId)
    };
  }
  getValue() {
    return this.value;
  }
}
class SubscriptionList {
  constructor() {
    this._list = {};
    return new Proxy(this, {
      get(obj, key) {
        if (key in obj) {
          return obj[key];
        }
        if (key in obj._list) {
          return obj._list[key];
        }
        return null;
      },
      set(obj, key, value) {
        obj.unsubscribeSafe(key);
        obj._list[key] = value;
        return true;
      }
    });
  }
  unsubscribeSafe(pipename) {
    if (this._list[pipename]) {
      this._list[pipename].unsubscribe();
    }
  }
  unsubscribeAllSafe() {
    for (const pipename in this._list) {
      this.unsubscribeSafe(pipename);
    }
  }
}
export { BehaviorSubject as B, SubjectBase as S, SubscriptionList as a };