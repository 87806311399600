import { AsyncPipe, JsonPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject,
  CUSTOM_ELEMENTS_SCHEMA,
} from '@angular/core';
import { Observable, from, of, switchMap } from 'rxjs';
import {
  watchAuthLoading,
  watchCurrentUser,
  getAccessToken,
  UserInfoModel,
  watchIsLoggedIn,
  getIdToken,
} from 'shell-browser/auth.js';
import { watchConsentRecords } from 'shell-browser/consent-management.js';
import {
  watchActiveRegion,
  RegionImpl,
  LanguageDetailModel,
  watchAvailableLanguagesForActiveRegion,
} from 'shell-browser/region.js';

import { ButtonsAndIndicatorsModule } from '@one/angular/dist/buttons-and-indicators';
import { LayoutModule } from '@one/angular/dist/layout';
import { TableModule } from '@one/angular/dist/table';

import { NgShellRxjsWrapperService } from '../../shell/ng-shell-wrapper.service';
import { TranslocoRootModule } from '../../transloco-root.module';

@Component({
  selector: 'app-shell-info',
  templateUrl: './shell-info-page.component.html',
  styleUrls: ['./shell-info-page.component.scss'],
  imports: [
    AsyncPipe,
    JsonPipe,
    LayoutModule,
    TableModule,
    ButtonsAndIndicatorsModule,
    TranslocoRootModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShellInfoPageComponent implements OnInit {
  ngShellService = inject(NgShellRxjsWrapperService);

  userInfo$: Observable<UserInfoModel | null>;
  authToken$: Observable<string | null>;
  idToken$: Observable<string | null>;
  activeRegion$: Observable<RegionImpl>;
  isAuthLoading$: Observable<boolean>;
  isLoggedIn$: Observable<boolean>;
  acceptedCookies$: Observable<string[]>;
  availableLanguagesForCurrentRegion$: Observable<LanguageDetailModel[]>;

  public ngOnInit(): void {
    this.loadDataFromShell();
  }

  private loadDataFromShell(): void {
    this.userInfo$ = this.ngShellService.toObservable(watchCurrentUser);
    this.activeRegion$ = this.ngShellService.toObservable(watchActiveRegion);
    this.isAuthLoading$ = this.ngShellService.toObservable(watchAuthLoading);
    this.isLoggedIn$ = this.ngShellService.toObservable(watchIsLoggedIn);
    this.authToken$ = this.isLoggedIn$.pipe(
      switchMap(isLoggedIn => (isLoggedIn ? from(getAccessToken()) : of(null))),
    );
    this.idToken$ = from(getIdToken());
    this.acceptedCookies$ =
      this.ngShellService.toObservable(watchConsentRecords);
    this.availableLanguagesForCurrentRegion$ = this.ngShellService.toObservable(
      watchAvailableLanguagesForActiveRegion,
    );
  }
}
