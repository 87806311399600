import { p as proxyCustomElement, H, h, c as Host } from './p-92c4a1ea.js';
import { n as noop } from './p-83f5cc12.js';
import { g as getDocument } from './p-a57042e3.js';
import { b as accessibility } from './p-756e7dc5.js';
import { a as ComponentUtils } from './p-de830ba7.js';
const iconCss = ".sc-owc-icon-h{font-family:\"One icons Outlined\";font-weight:normal;font-style:normal;font-size:var(--one-spacing-size-s);height:auto;width:auto;display:inline-block;line-height:1;text-transform:none;letter-spacing:normal;word-wrap:normal;white-space:nowrap;direction:ltr;cursor:default;-webkit-font-smoothing:antialiased;text-rendering:optimizeLegibility;-moz-osx-font-smoothing:grayscale;-webkit-font-feature-settings:\"liga\";font-feature-settings:\"liga\"}.sc-owc-icon-h:has(svg){width:var(--one-spacing-size-s);height:var(--one-spacing-size-s)}.sc-owc-icon-h svg.sc-owc-icon{width:inherit;height:inherit}.owc-icon--filled.sc-owc-icon-h{font-family:\"One icons Filled\"}.owc-icon--outlined.sc-owc-icon-h{font-family:\"One icons Outlined\"}.owc-icon--default.sc-owc-icon-h{color:var(--one-color-foreground-base)}.owc-icon--inherit.sc-owc-icon-h{color:inherit}.owc-icon--info.sc-owc-icon-h{color:var(--one-color-interaction-default-brand-base)}.owc-icon--positive.sc-owc-icon-h{color:var(--one-color-interaction-default-positive-base)}.owc-icon--warning.sc-owc-icon-h{color:var(--one-color-interaction-default-warning-base)}.owc-icon--alarm.sc-owc-icon-h{color:var(--one-color-interaction-default-negative-base)}@media (forced-colors: active){.sc-owc-icon-h{color:canvastext}}.owc-icon--not-loaded.sc-owc-icon-h{overflow:hidden !important;width:1em !important;color:transparent !important}";
const OwcIconStyle0 = iconCss;
const Icons = /*@__PURE__*/proxyCustomElement(class Icons extends H {
  constructor() {
    super();
    this.__registerHost();
    this.component = new ComponentUtils(this);
    this.getFontsInDocument = () => {
      var _a, _b;
      // do NOT create an array from iterator by using "Array.from" since it is not working as expected in Mozilla
      const iterator = (_b = (_a = getDocument()) === null || _a === void 0 ? void 0 : _a.fonts) === null || _b === void 0 ? void 0 : _b.values();
      const fonts = [];
      if (iterator) {
        for (let next = iterator.next(); !next.done; next = iterator.next()) {
          fonts.push(next.value);
        }
      }
      return fonts;
    };
    this.onFontLoadingDone = ev => this.loadedFonts = [...this.loadedFonts, ...ev.fontfaces];
    this.name = undefined;
    this.status = 'default';
    this.family = 'outlined';
    this.alt = undefined;
    this.fontLoaded = false;
    this.loadedFonts = [];
  }
  onTypeChanged() {
    this.checkFontLoaded();
  }
  componentDidLoad() {
    this.checkFontLoaded();
  }
  connectedCallback() {
    var _a, _b;
    this.cleanup();
    (_b = (_a = getDocument()) === null || _a === void 0 ? void 0 : _a.fonts) === null || _b === void 0 ? void 0 : _b.addEventListener('loadingdone', this.onFontLoadingDone);
  }
  disconnectedCallback() {
    this.cleanup();
  }
  cleanup() {
    var _a, _b;
    (_b = (_a = getDocument()) === null || _a === void 0 ? void 0 : _a.fonts) === null || _b === void 0 ? void 0 : _b.removeEventListener('loadingdone', this.onFontLoadingDone);
  }
  checkFontLoaded() {
    var _a;
    (_a = [...this.getFontsInDocument(), ...this.loadedFonts].find(font => font.family.toLowerCase().includes(`one icons ${this.family || ''}`.toLowerCase()))) === null || _a === void 0 ? void 0 : _a.loaded.then(() => this.fontLoaded = true).catch(noop);
  }
  render() {
    var _a;
    const hostClasses = this.component.getHostBemClasses({
      [this.family]: true,
      [this.status]: true,
      'not-loaded': !this.fontLoaded
    });
    return h(Host, Object.assign({
      key: '58ea4cec35e50604356590259f55979cc5d22ba0',
      class: hostClasses
    }, accessibility({
      role: 'img',
      aria: {
        label: (_a = this.alt) !== null && _a !== void 0 ? _a : this.name
      }
    })), h("slot", {
      key: 'ad528effcb8ed2d6ccb66e643c37bee96a921582'
    }, this.name));
  }
  get hostElement() {
    return this;
  }
  static get watchers() {
    return {
      "family": ["onTypeChanged"],
      "loadedFonts": ["onTypeChanged"]
    };
  }
  static get style() {
    return OwcIconStyle0;
  }
}, [6, "owc-icon", {
  "name": [513],
  "status": [513],
  "family": [513],
  "alt": [513],
  "fontLoaded": [32],
  "loadedFonts": [32]
}, undefined, {
  "family": ["onTypeChanged"],
  "loadedFonts": ["onTypeChanged"]
}]);
function defineCustomElement() {
  if (typeof customElements === "undefined") {
    return;
  }
  const components = ["owc-icon"];
  components.forEach(tagName => {
    switch (tagName) {
      case "owc-icon":
        if (!customElements.get(tagName)) {
          customElements.define(tagName, Icons);
        }
        break;
    }
  });
}
export { Icons as I, defineCustomElement as d };