import { p as proxyCustomElement, H, h, c as Host } from './p-92c4a1ea.js';
import { g as getDocument } from './p-a57042e3.js';
const IconProvider = /*@__PURE__*/proxyCustomElement(class IconProvider extends H {
  constructor() {
    super();
    this.__registerHost();
    this.getLinkId = family => `owc-icon-provider-${family}`;
    this.base = './';
    this.family = 'filled';
  }
  getFamilies() {
    return (Array.isArray(this.family) ? this.family : this.family.split(',').filter(p => p.trim())).filter(Boolean);
  }
  updateStylesheetImports() {
    this.getFamilies().forEach(family => {
      var _a, _b, _c;
      const filename = `one-icons-${family.toLowerCase()}`;
      const href = `${[this.base, filename].filter(Boolean).join('')}.css`;
      const existingFonts = (_a = getDocument()) === null || _a === void 0 ? void 0 : _a.querySelectorAll(`[href*="${href}"]`);
      if (!existingFonts.length) {
        const linkElement = (_b = getDocument()) === null || _b === void 0 ? void 0 : _b.createElement('link');
        linkElement.rel = 'stylesheet';
        linkElement.id = this.getLinkId(family);
        linkElement.crossOrigin = '';
        linkElement.href = href;
        (_c = getDocument()) === null || _c === void 0 ? void 0 : _c.head.appendChild(linkElement);
      } else {
        existingFonts.forEach(element => element.id = this.getLinkId(family));
      }
    });
  }
  componentDidLoad() {
    this.updateStylesheetImports();
  }
  componentWillRender() {
    this.updateStylesheetImports();
  }
  render() {
    return h(Host, {
      key: '7f11373335ca48008862be35e23e3dd127615d6e'
    }, h("slot", {
      key: 'a0f4a220298da40ae7d78f0ca5666d4ac19c7a87'
    }));
  }
  get hostElement() {
    return this;
  }
}, [6, "owc-icon-provider", {
  "base": [1],
  "family": [1]
}]);
function defineCustomElement$1() {
  if (typeof customElements === "undefined") {
    return;
  }
  const components = ["owc-icon-provider"];
  components.forEach(tagName => {
    switch (tagName) {
      case "owc-icon-provider":
        if (!customElements.get(tagName)) {
          customElements.define(tagName, IconProvider);
        }
        break;
    }
  });
}
const OwcIconProvider = IconProvider;
const defineCustomElement = defineCustomElement$1;
export { OwcIconProvider, defineCustomElement };