import { H as getComputedStyleSafe, a as getBody, aq as isNumber, i as isSSR, f as OWC_GLOBAL_KEY, S as SCREEN_GLOBAL_KEY, g as getDocument, G as GLOBAL_EVENTS, ac as KeydownManager, ae as deepMerge, J as isElementReady, a5 as createBEMModifier, at as classNames, a4 as createBEMElement, k as RUNTIME_OWC_VERSION_GLOBAL_KEY, D as DOC_MUTATION_OBSERVER_GLOBAL_KEY, c as MutationObserverSafe, l as DOC_RESIZE_OBSERVER_GLOBAL_KEY, d as ResizeObserverSafe, m as SCREEN_WIDTH_GLOBAL_KEY, n as SCREEN_SIZE_GLOBAL_KEY, ai as isDefined, P as PERFORMANCE_API_GLOBAL_KEY } from './p-a57042e3.js';
const isChildMutated = (records, parent) => {
  const filteredRecords = records.filter(({
    type,
    target
  }) => type === 'childList' || type === 'attributes' && target.nodeName.toUpperCase() !== (parent === null || parent === void 0 ? void 0 : parent.tagName.toUpperCase()));
  return !!filteredRecords.length;
};
const getWidthFromResizeObserverEntry = entry => {
  let width = 0;
  if (entry.contentBoxSize) {
    // Firefox implements `contentBoxSize` as a single content rect, rather than an array
    const contentBoxSize = Array.isArray(entry.contentBoxSize) ? entry.contentBoxSize[0] : entry.contentBoxSize;
    width = contentBoxSize.inlineSize;
  } else {
    width = entry.contentRect.width;
  }
  return width;
};
const inBetween = (value, min, max) => min <= Math.floor(value) && Math.floor(value) <= max;
const getBreakpoints = () => {
  const computedStyles = getComputedStyleSafe(getBody());
  const getter = key => {
    var _a;
    return (_a = parseInt(computedStyles === null || computedStyles === void 0 ? void 0 : computedStyles.getPropertyValue(key), 10)) !== null && _a !== void 0 ? _a : 0;
  };
  return {
    xs: {
      min: getter('--one-media-breakpoint-xs-min'),
      max: getter('--one-media-breakpoint-xs-max')
    },
    sm: {
      min: getter('--one-media-breakpoint-s-min'),
      max: getter('--one-media-breakpoint-s-max')
    },
    md: {
      min: getter('--one-media-breakpoint-m-min'),
      max: getter('--one-media-breakpoint-m-max')
    },
    lg: {
      min: getter('--one-media-breakpoint-l-min'),
      max: getter('--one-media-breakpoint-l-max')
    },
    xl: {
      min: getter('--one-media-breakpoint-xl-min'),
      max: getter('--one-media-breakpoint-xl-max')
    }
  };
};
const getScreenSize = width => {
  const breakpoints = getBreakpoints();
  if (width < breakpoints.sm.min) {
    return 'xs';
  } else if (inBetween(width, breakpoints.sm.min, breakpoints.sm.max)) {
    return 'sm';
  } else if (inBetween(width, breakpoints.md.min, breakpoints.md.max)) {
    return 'md';
  } else if (inBetween(width, breakpoints.lg.min, breakpoints.lg.max)) {
    return 'lg';
  } else {
    return 'xl';
  }
};
const getSm = input => input.sm || input.xs;
const getMd = input => input.md || getSm(input);
const getLg = input => input.lg || getMd(input);
const getXl = input => input.xl || getLg(input);
const getResponsiveAttributeValue = (input, screenSize, defaultValue) => {
  if (isNumber(input)) {
    return +input;
  } else if (typeof input === 'string') {
    return input;
  } else if (typeof input === 'object') {
    switch (screenSize) {
      case 'xl':
        {
          return getResponsiveAttributeValue(getXl(input), screenSize, defaultValue);
        }
      case 'lg':
        {
          return getResponsiveAttributeValue(getLg(input), screenSize, defaultValue);
        }
      case 'md':
        {
          return getResponsiveAttributeValue(getMd(input), screenSize, defaultValue);
        }
      case 'sm':
        {
          return getResponsiveAttributeValue(getSm(input), screenSize, defaultValue);
        }
      case 'xs':
        {
          return getResponsiveAttributeValue(input.xs, screenSize, defaultValue);
        }
      default:
        {
          return defaultValue;
        }
    }
  } else {
    return defaultValue;
  }
};
const getAttributes = (element, omit = []) => {
  if (!(element && element.attributes)) {
    return {};
  }
  const omitThese = [...(omit || []), 'ref'];
  const attrs = element.getAttribute('class') ? {
    className: element.getAttribute('class')
  } : {};
  for (let i = 0; i < element.attributes.length; i++) {
    const attr = element.attributes.item(i);
    if (!omitThese.includes(attr.name)) {
      attrs[attr.name] = attr.value;
    }
  }
  return attrs;
};
const getAttribute = (element, attr) => {
  var _a, _b, _c;
  if (!(element && element.attributes)) {
    return '';
  }
  return attr === 'className' || attr === 'class' ? ((_a = element.attributes.getNamedItem('className')) === null || _a === void 0 ? void 0 : _a.value) || ((_b = element.attributes.getNamedItem('class')) === null || _b === void 0 ? void 0 : _b.value) : (_c = element.attributes.getNamedItem(attr)) === null || _c === void 0 ? void 0 : _c.value;
};
const removeAttributes = (element, attrs = []) => {
  if (!element) {
    return;
  }
  (attrs || []).forEach(attr => element.removeAttribute(attr));
};
const parseStyles = styles => {
  return typeof styles === 'string' ? (styles || '').split(';').filter(style => style.split(':')[0] && style.split(':')[1]).map(style => {
    const name = style.split(':')[0].trim();
    return [name.startsWith('--') ? name : name.replace(/-./g, c => c.substring(1).toUpperCase()), style.split(':')[1].trim()];
  }).reduce((styleObj, style) => Object.assign(Object.assign({}, styleObj), {
    [style[0]]: style[1]
  }), {}) : styles;
};
const componentMethodWrapper = (def, name, wrapper) => {
  const original = def[name];
  def[name] = (...fnArgs) => {
    typeof wrapper === 'function' && wrapper(...fnArgs);
    if (typeof original === 'function') {
      return original.call(def, fnArgs);
    }
  };
};
const FRACTION_DIGIT = 2;
const CONSOLE_HEADER_STYLE = 'font-size: 24px; font-weight: 800; color: #0b41cd; background-color: #daedfb; padding: 8px; border-radius: 4px; margin-top: 32px';
const LIFECYCLE_EVENT_MAP = {
  beforeLoad: 'componentWillLoad',
  afterLoad: 'componentDidLoad',
  shouldUpdate: 'componentShouldUpdate',
  beforeUpdate: 'componentWillUpdate',
  afterUpdate: 'componentDidUpdate',
  beforeRender: 'componentWillRender',
  afterRender: 'componentDidRender'
};
class ComponentPerformance {
  constructor(def) {
    this.def = def;
    this._renderIteration = 0;
    this._rerenderDurations = [];
    this._logs = [];
    /* INITIAL LOAD */
    componentMethodWrapper(this.def, LIFECYCLE_EVENT_MAP.beforeLoad, () => {
      this._connectedTimestamp = performance.now();
      this._logs.push({
        renderIteration: this._renderIteration,
        action: LIFECYCLE_EVENT_MAP.beforeLoad,
        timestamp: this._connectedTimestamp
      });
    });
    componentMethodWrapper(this.def, LIFECYCLE_EVENT_MAP.afterLoad, () => {
      const timestamp = performance.now();
      this._logs.push({
        renderIteration: this._renderIteration,
        action: LIFECYCLE_EVENT_MAP.afterLoad,
        timestamp
      });
      this._componentLoadDuration = timestamp - this._connectedTimestamp;
      this._renderIteration = this._renderIteration + 1;
    });
    /* INITIAL LOAD */
    /* PROP or STATE UPDATE */
    componentMethodWrapper(this.def, LIFECYCLE_EVENT_MAP.shouldUpdate, (newValue, oldValue, name) => {
      this._logs.push({
        renderIteration: this._renderIteration,
        action: LIFECYCLE_EVENT_MAP.shouldUpdate,
        timestamp: performance.now(),
        payload: {
          name,
          newValue,
          oldValue
        }
      });
    });
    componentMethodWrapper(this.def, LIFECYCLE_EVENT_MAP.beforeUpdate, () => {
      this._logs.push({
        renderIteration: this._renderIteration,
        action: LIFECYCLE_EVENT_MAP.beforeUpdate,
        timestamp: performance.now()
      });
    });
    componentMethodWrapper(this.def, LIFECYCLE_EVENT_MAP.afterUpdate, () => {
      this._logs.push({
        renderIteration: this._renderIteration,
        action: LIFECYCLE_EVENT_MAP.afterUpdate,
        timestamp: performance.now()
      });
      this._renderIteration = this._renderIteration + 1;
    });
    /* PROP or STATE UPDATE */
    /* RENDER */
    componentMethodWrapper(this.def, LIFECYCLE_EVENT_MAP.beforeRender, () => {
      this._logs.push({
        renderIteration: this._renderIteration,
        action: LIFECYCLE_EVENT_MAP.beforeRender,
        timestamp: performance.now()
      });
    });
    componentMethodWrapper(this.def, LIFECYCLE_EVENT_MAP.afterRender, () => {
      var _a, _b;
      const timestamp = performance.now();
      const lastWillRenderTimestamp = ((_b = (_a = this._logs.filter(log => log.action === LIFECYCLE_EVENT_MAP.beforeRender)) === null || _a === void 0 ? void 0 : _a.slice(-1)[0]) === null || _b === void 0 ? void 0 : _b.timestamp) || 0;
      this._logs.push({
        renderIteration: this._renderIteration,
        action: LIFECYCLE_EVENT_MAP.afterRender,
        timestamp: performance.now()
      });
      this._rerenderDurations.push(timestamp - lastWillRenderTimestamp);
    });
    /* RENDER */
  }
  printComponentInfo() {
    console.log('%cCOMPONENT INFO', CONSOLE_HEADER_STYLE);
    console.table(this.getComponentInfo());
  }
  printLogs() {
    console.log('%cLOGS', CONSOLE_HEADER_STYLE);
    console.table(this._logs);
  }
  printTimings() {
    console.log('%cTIMELINE', CONSOLE_HEADER_STYLE);
    console.table(this.getTimings());
  }
  printDurations() {
    console.log('%cDURATIONS (ms)', CONSOLE_HEADER_STYLE);
    console.table(this.getDurations());
  }
  printAll() {
    this.printComponentInfo();
    this.printTimings();
    this.printDurations();
    this.printLogs();
  }
  export() {
    var _a;
    const rows = [['Key', 'Value'], ...Object.entries(this.getComponentInfo()), ...Object.entries(this.getDurations())];
    const content = `data:text/csv;charset=utf-8,${rows.map(e => e.join(',')).join('\n')}`;
    const encodedUri = encodeURI(content);
    if (!isSSR()) {
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', `${(_a = this.def.hostElement.tagName) === null || _a === void 0 ? void 0 : _a.toLowerCase()}_performance-report.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  }
  getComponentInfo() {
    return {
      Tag: this.def.hostElement.tagName,
      Id: this.def.hostElement.id,
      Content: this.def.hostElement.textContent
    };
  }
  getTimings() {
    var _a, _b;
    let firstPaint;
    let firstContentfulPaint;
    let largestContentfulPaint;
    for (const entry of performance.getEntries()) {
      if (entry.entryType === 'paint' && entry.name === 'first-contentful-paint') {
        firstContentfulPaint = entry.startTime;
      } else if (entry.entryType === 'paint' && entry.name === 'first-paint') {
        firstPaint = entry.startTime;
      } else if (entry.entryType === 'largest-contentful-paint') {
        largestContentfulPaint = entry.startTime;
      }
    }
    return {
      'Component Connected': this.formatTime(((_a = this._logs.find(log => log.action === LIFECYCLE_EVENT_MAP.beforeLoad)) === null || _a === void 0 ? void 0 : _a.timestamp) || 0),
      'Component Loaded': this.formatTime(((_b = this._logs.find(log => log.action === LIFECYCLE_EVENT_MAP.afterLoad)) === null || _b === void 0 ? void 0 : _b.timestamp) || 0),
      'FP - First Paint': this.formatTime(firstPaint),
      'FCP - First Contentful Paint': this.formatTime(firstContentfulPaint),
      'LCP - Largest Contentful Paint': this.formatTime(largestContentfulPaint)
    };
  }
  getDurations() {
    var _a, _b, _c, _d;
    const pagePerformanceEntries = (_a = performance.getEntries()) === null || _a === void 0 ? void 0 : _a.reverse();
    const fpTimestamp = ((_b = pagePerformanceEntries.find(entry => entry.entryType === 'paint' && entry.name === 'first-paint')) === null || _b === void 0 ? void 0 : _b.startTime) || 0;
    const fcpTimestamp = ((_c = pagePerformanceEntries.find(entry => entry.entryType === 'paint' && entry.name === 'first-contentful-paint')) === null || _c === void 0 ? void 0 : _c.startTime) || 0;
    const lcpTimestamp = ((_d = pagePerformanceEntries.find(entry => entry.entryType === 'largest-contentful-paint')) === null || _d === void 0 ? void 0 : _d.startTime) || 0;
    return {
      'Initial Load Duration': this.formatTime(this._componentLoadDuration),
      'Avg. Render Duration': this.formatTime(this._rerenderDurations.reduce((total, item) => total + item, 0) / this._rerenderDurations.length),
      'Slowest Render Duration': this.formatTime(Math.max(...this._rerenderDurations)),
      'Fastest Render Duration': this.formatTime(Math.min(...this._rerenderDurations)),
      'Total Render Count': this._renderIteration,
      'FP (First Paint) Duration': this.formatTime(Math.max(0, fpTimestamp - this._connectedTimestamp)),
      'FCP (First Contentful Paint) Duration': this.formatTime(Math.max(0, fcpTimestamp - this._connectedTimestamp)),
      'LCP (Largest Contentful Paint) Duration': this.formatTime(Math.max(0, lcpTimestamp - this._connectedTimestamp))
    };
  }
  formatTime(time) {
    return typeof time === 'number' ? Number.parseFloat(time.toFixed(FRACTION_DIGIT)) : time;
  }
}

/* eslint-disable */
function debounce(func, wait) {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func === null || func === void 0 ? void 0 : func(...args), wait);
  };
}
const DOC_RESIZE_DEBOUNCE_TIMEOUT = 10;
const DEFAULT_COMPONENT_CONFIG = {};
class ComponentUtils {
  constructor(def, config = DEFAULT_COMPONENT_CONFIG) {
    this.def = def;
    this._loaded = false;
    this._config = {};
    this.dispatchDocumentResizedEvent = detail => {
      var _a;
      !(OWC_GLOBAL_KEY in window) && (window[OWC_GLOBAL_KEY] = {});
      window[OWC_GLOBAL_KEY][SCREEN_GLOBAL_KEY] = Object.assign(Object.assign({}, window[OWC_GLOBAL_KEY][SCREEN_GLOBAL_KEY] || {}), detail);
      (_a = getDocument()) === null || _a === void 0 ? void 0 : _a.dispatchEvent(new CustomEvent(GLOBAL_EVENTS.DOCUMENT_RESIZED, {
        detail
      }));
    };
    this.debouncedDispatchDocumentResizedEvent = debounce(this.dispatchDocumentResizedEvent, DOC_RESIZE_DEBOUNCE_TIMEOUT);
    this._config = Object.assign({}, config);
    this.setupOWCVersion().setupWindowSizeObserver().setupDocumentMutationObserver().setupPerformanceMeasurement().setupMethodWrappers();
  }
  isLoaded() {
    return this._loaded;
  }
  withKeydownManager(opts, target = this.def.hostElement) {
    let currentIndex = 0;
    if (this._keydownManager) {
      currentIndex = this._keydownManager.getCurrentIndex();
      this._keydownManager.detachEventListeners();
    }
    this._keydownManager = new KeydownManager(target, Array.isArray(opts) ? deepMerge({}, ...opts) : opts);
    this._keydownManager.setStartIndex(currentIndex);
    return this;
  }
  withKeydownManagerLazy(targetLazy, opts) {
    isElementReady(targetLazy).then(target => {
      let currentIndex = 0;
      if (this._keydownManager) {
        currentIndex = this._keydownManager.getCurrentIndex();
        this._keydownManager.detachEventListeners();
      }
      this._keydownManager = new KeydownManager(target, Array.isArray(opts) ? deepMerge({}, ...opts) : opts);
      this._keydownManager.setStartIndex(currentIndex);
    });
    return this;
  }
  keydownManager() {
    return this._keydownManager;
  }
  getAttribute(attr) {
    return getAttribute(this.def.hostElement, attr);
  }
  getScopeId() {
    var _a, _b, _c;
    return ['sc', (_c = (_b = (_a = this.def) === null || _a === void 0 ? void 0 : _a.hostElement) === null || _b === void 0 ? void 0 : _b.tagName) === null || _c === void 0 ? void 0 : _c.toLowerCase()].filter(Boolean).join('-');
  }
  getHostBemClasses(modifiers = {}) {
    var _a, _b;
    const currentClasses = this.getAttribute('class');
    const componentNameWithPrefix = ((_b = (_a = this.def) === null || _a === void 0 ? void 0 : _a.hostElement) === null || _b === void 0 ? void 0 : _b.tagName.toLowerCase()) || '';
    const existingClasses = (currentClasses || '').split(' ').filter(cls => !cls.startsWith(`${componentNameWithPrefix}--`));
    const stateClasses = Object.keys(modifiers).filter(modifier => modifiers[modifier]).map(modifier => createBEMModifier(componentNameWithPrefix, modifier));
    return classNames(Array.from(new Set([createBEMElement(componentNameWithPrefix), ...existingClasses, ...stateClasses])));
  }
  getChildBemClasses(element, parent = '', modifiers = {}, userDefinedClasses = '') {
    var _a, _b;
    const componentNameWithPrefix = ((_b = (_a = this.def) === null || _a === void 0 ? void 0 : _a.hostElement) === null || _b === void 0 ? void 0 : _b.tagName.toLowerCase()) || '';
    const parents = (parent || createBEMElement(componentNameWithPrefix)).split(' ').filter(cls => cls.startsWith(componentNameWithPrefix) && !cls.includes('--'));
    const elementClasses = parents.map(parentClass => createBEMElement(element, parentClass));
    const states = Object.keys(modifiers).filter(modifier => modifiers[modifier]);
    const stateClasses = elementClasses.reduce((acc, elementClass) => [...acc, ...states.map(state => createBEMModifier(elementClass, state))], []);
    return classNames([...elementClasses, ...stateClasses, userDefinedClasses]);
  }
  getInitialTabIndex(defaultValue) {
    var _a;
    return (_a = this._initialTabIndex) !== null && _a !== void 0 ? _a : defaultValue;
  }
  setupOWCVersion() {
    if (!isSSR()) {
      !(OWC_GLOBAL_KEY in window) && (window[OWC_GLOBAL_KEY] = {});
      if (!(RUNTIME_OWC_VERSION_GLOBAL_KEY in window[OWC_GLOBAL_KEY])) {
        window[OWC_GLOBAL_KEY][RUNTIME_OWC_VERSION_GLOBAL_KEY] = "7.1.0";
      }
    }
    return this;
  }
  setupDocumentMutationObserver() {
    if (!isSSR()) {
      !(OWC_GLOBAL_KEY in window) && (window[OWC_GLOBAL_KEY] = {});
      if (!(DOC_MUTATION_OBSERVER_GLOBAL_KEY in window[OWC_GLOBAL_KEY])) {
        window[OWC_GLOBAL_KEY][DOC_MUTATION_OBSERVER_GLOBAL_KEY] = new MutationObserverSafe(records => {
          var _a;
          return (_a = getDocument()) === null || _a === void 0 ? void 0 : _a.dispatchEvent(new CustomEvent(GLOBAL_EVENTS.DOCUMENT_CHANGED, {
            detail: records
          }));
        });
        window[OWC_GLOBAL_KEY][DOC_MUTATION_OBSERVER_GLOBAL_KEY].observe(getDocument(), {
          childList: true,
          subtree: true
        });
      }
    }
    return this;
  }
  setupWindowSizeObserver() {
    if (!isSSR()) {
      !(OWC_GLOBAL_KEY in window) && (window[OWC_GLOBAL_KEY] = {});
      if (!(DOC_RESIZE_OBSERVER_GLOBAL_KEY in window[OWC_GLOBAL_KEY])) {
        window[OWC_GLOBAL_KEY][DOC_RESIZE_OBSERVER_GLOBAL_KEY] = new ResizeObserverSafe((entries, _observer) => {
          for (const entry of entries) {
            const width = getWidthFromResizeObserverEntry(entry);
            this.debouncedDispatchDocumentResizedEvent({
              [SCREEN_WIDTH_GLOBAL_KEY]: width,
              [SCREEN_SIZE_GLOBAL_KEY]: getScreenSize(width)
            });
          }
        });
        window[OWC_GLOBAL_KEY][DOC_RESIZE_OBSERVER_GLOBAL_KEY].observe(getDocument().documentElement);
      }
    }
    return this;
  }
  setupMethodWrappers() {
    componentMethodWrapper(this.def, 'disconnectedCallback', () => {
      var _a;
      this.isLoaded() && ((_a = this._keydownManager) === null || _a === void 0 ? void 0 : _a.detachEventListeners());
    });
    componentMethodWrapper(this.def, 'componentWillLoad', () => {
      const tabIndex = this.getAttribute('tabindex');
      this._initialTabIndex = isDefined(tabIndex) ? +tabIndex : undefined;
    });
    componentMethodWrapper(this.def, 'componentDidLoad', () => {
      var _a, _b;
      const focusPatchFn = (_a = this._config.patchFocusMethod) !== null && _a !== void 0 ? _a : DEFAULT_COMPONENT_CONFIG.patchFocusMethod;
      typeof focusPatchFn === 'function' && this.wrapHostElementMethod('focus', focusPatchFn);
      const blurPatchFn = (_b = this._config.patchBlurMethod) !== null && _b !== void 0 ? _b : DEFAULT_COMPONENT_CONFIG.patchBlurMethod;
      typeof blurPatchFn === 'function' && this.wrapHostElementMethod('blur', blurPatchFn);
      this._loaded = true;
    });
    return this;
  }
  setupPerformanceMeasurement() {
    var _a, _b;
    if (!isSSR() && ((_b = (_a = window.owc) === null || _a === void 0 ? void 0 : _a[PERFORMANCE_API_GLOBAL_KEY]) === null || _b === void 0 ? void 0 : _b.isEnabled())) {
      this._performance = new ComponentPerformance(this.def);
      Object.defineProperty(this.def.hostElement, 'performance', {
        enumerable: true,
        value: this._performance
      });
    }
    return this;
  }
  wrapHostElementMethod(name, wrapper) {
    const host = this.def.hostElement;
    const originalName = `__original_${String(name)}`;
    const descriptor = Object.getOwnPropertyDescriptor(host, name);
    descriptor && Object.defineProperty(host, originalName, descriptor);
    Object.defineProperty(host, name, {
      configurable: true,
      value() {
        typeof wrapper === 'function' && wrapper.call(host);
        if (typeof this[originalName] === 'function') {
          return this[originalName].call(host);
        }
        return this[originalName];
      }
    });
    return this;
  }
}
export { ComponentPerformance as C, ComponentUtils as a, getBreakpoints as b, componentMethodWrapper as c, getScreenSize as d, getResponsiveAttributeValue as e, getAttributes as f, getWidthFromResizeObserverEntry as g, getAttribute as h, isChildMutated as i, debounce as j, parseStyles as p, removeAttributes as r };