import { p as proxyCustomElement, H, h, c as Host } from './p-92c4a1ea.js';
import './p-a57042e3.js';
import { b as accessibility } from './p-756e7dc5.js';
import { a as ComponentUtils } from './p-de830ba7.js';
const footerCss = ".sc-owc-footer-h{width:100%;background:inherit;-webkit-box-sizing:border-box;box-sizing:border-box;font-family:var(--one-typography-font-family);font-size:inherit !important;line-height:inherit !important;-webkit-font-kerning:inherit !important;font-kerning:inherit !important;font-weight:inherit !important;z-index:var(--one-z-index-footer)}.sc-owc-footer-h [slot=top].sc-owc-footer{-webkit-box-shadow:var(--one-footer-box-shadow-top);box-shadow:var(--one-footer-box-shadow-top)}.owc-footer--variant-default.sc-owc-footer-h [slot=top].sc-owc-footer{background-color:var(--one-color-background-base)}.owc-footer--variant-default.sc-owc-footer-h [slot=bottom].sc-owc-footer{background-color:var(--one-footer-color-default-background);color:var(--one-footer-color-default-foreground)}.owc-footer--variant-global.sc-owc-footer-h{background:var(--one-footer-color-global-background);color:var(--one-footer-color-global-foreground)}.owc-footer--fix-bottom.sc-owc-footer-h{bottom:0;left:0;right:0;position:fixed}";
const OwcFooterStyle0 = footerCss;
const Footer = /*@__PURE__*/proxyCustomElement(class Footer extends H {
  constructor() {
    super();
    this.__registerHost();
    this.component = new ComponentUtils(this);
    this.fixBottom = false;
    this.variant = 'default';
  }
  render() {
    const hostClasses = this.component.getHostBemClasses({
      [`variant-${this.variant || 'default'}`]: true,
      'fix-bottom': !!this.fixBottom
    });
    return h(Host, Object.assign({
      key: '31b6f814d103b6728799dc5797a49bede248c2cc',
      class: hostClasses
    }, accessibility({
      role: 'contentinfo'
    })), h("slot", {
      key: '6dd1e4b06c8684cb02df53b88d8d6e2f55fb09a1',
      name: "top"
    }), h("slot", {
      key: '3406a9638a5a2a8dc855adf82dd6f5765f50b4a1',
      name: "bottom"
    }));
  }
  get hostElement() {
    return this;
  }
  static get style() {
    return OwcFooterStyle0;
  }
}, [6, "owc-footer", {
  "fixBottom": [516, "fix-bottom"],
  "variant": [513]
}]);
function defineCustomElement$1() {
  if (typeof customElements === "undefined") {
    return;
  }
  const components = ["owc-footer"];
  components.forEach(tagName => {
    switch (tagName) {
      case "owc-footer":
        if (!customElements.get(tagName)) {
          customElements.define(tagName, Footer);
        }
        break;
    }
  });
}
const OwcFooter = Footer;
const defineCustomElement = defineCustomElement$1;
export { OwcFooter, defineCustomElement };