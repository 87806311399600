import { p as proxyCustomElement, H, h, c as Host } from './p-92c4a1ea.js';
import { n as SCREEN_SIZE_GLOBAL_KEY, f as OWC_GLOBAL_KEY, S as SCREEN_GLOBAL_KEY, g as getDocument, i as isSSR, am as hasValue } from './p-a57042e3.js';
import { a as ComponentUtils, d as getScreenSize, e as getResponsiveAttributeValue } from './p-de830ba7.js';
import { a as Context, C as ContextValue } from './p-f5bb9925.js';
import { C as CTX_KEYS } from './p-16b4fd0e.js';
const gridCss = ".sc-owc-grid-h{padding-left:var(--grid-container-margin, 0);padding-right:var(--grid-container-margin, 0);width:100%;display:grid;-webkit-box-sizing:border-box;box-sizing:border-box;container-name:owc-grid-container;container-type:inline-size}";
const OwcGridStyle0 = gridCss;
var __decorate = undefined && undefined.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
const DEFAULT_COLUMN_COUNT = 12;
const REDUCED_COLUMN_COUNT = 8;
const Grid = /*@__PURE__*/proxyCustomElement(class Grid extends H {
  constructor() {
    super();
    this.__registerHost();
    this.ctxColumns = new ContextValue();
    this.component = new ComponentUtils(this);
    this.columns = 'auto';
    this.margin = 'auto';
    this.spacing = 'auto';
    this.horizontalSpacing = undefined;
    this.verticalSpacing = undefined;
  }
  onAttributesChanged() {
    this.updateStyles();
  }
  onDocumentResized(e) {
    const screenSize = this.getScreenSize(e.detail[SCREEN_SIZE_GLOBAL_KEY]);
    if (this.storedScreenSize !== screenSize) {
      this.storedScreenSize = screenSize;
      this.updateStyles(screenSize);
    }
  }
  componentDidLoad() {
    this.updateStyles();
  }
  getScreenSize(inputScreenSize) {
    var _a, _b, _c, _d, _e;
    return inputScreenSize || ((_b = (_a = window[OWC_GLOBAL_KEY]) === null || _a === void 0 ? void 0 : _a[SCREEN_GLOBAL_KEY]) === null || _b === void 0 ? void 0 : _b[SCREEN_SIZE_GLOBAL_KEY]) || getScreenSize((_e = (_d = (_c = getDocument()) === null || _c === void 0 ? void 0 : _c.documentElement) === null || _d === void 0 ? void 0 : _d.getBoundingClientRect()) === null || _e === void 0 ? void 0 : _e.width);
  }
  updateStyles(inputScreenSize) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    if (isSSR()) {
      return;
    }
    const screenSize = inputScreenSize || this.getScreenSize();
    const currentColumnCount = getResponsiveAttributeValue(this.columns === 'auto' ? {
      xs: REDUCED_COLUMN_COUNT,
      md: DEFAULT_COLUMN_COUNT
    } : this.columns, screenSize, DEFAULT_COLUMN_COUNT);
    const margin = getResponsiveAttributeValue(this.margin === 'auto' ? {
      xs: 'var(--one-spacing-padding-2-xs)',
      md: 'var(--one-spacing-padding-s)',
      xl: 'var(--one-spacing-padding-m)'
    } : this.margin, screenSize, 0);
    const spacing = getResponsiveAttributeValue(this.spacing === 'auto' ? {
      xs: 'var(--one-spacing-gap-2-xs)',
      lg: 'var(--one-spacing-gap-s)'
    } : this.spacing, screenSize, 0);
    const hSpacing = getResponsiveAttributeValue(this.horizontalSpacing, screenSize);
    const vSpacing = getResponsiveAttributeValue(this.verticalSpacing, screenSize);
    this.ctxColumns.get() !== currentColumnCount && this.ctxColumns.set(currentColumnCount);
    (_b = (_a = this.hostElement) === null || _a === void 0 ? void 0 : _a.style) === null || _b === void 0 ? void 0 : _b.setProperty(`grid-template-columns`, `repeat(${currentColumnCount || DEFAULT_COLUMN_COUNT}, 1fr)`);
    hasValue(spacing) && ((_d = (_c = this.hostElement) === null || _c === void 0 ? void 0 : _c.style) === null || _d === void 0 ? void 0 : _d.setProperty(`gap`, typeof spacing === 'number' ? `${spacing}px` : spacing));
    hasValue(hSpacing) && ((_f = (_e = this.hostElement) === null || _e === void 0 ? void 0 : _e.style) === null || _f === void 0 ? void 0 : _f.setProperty(`column-gap`, typeof hSpacing === 'number' ? `${hSpacing}px` : hSpacing));
    hasValue(vSpacing) && ((_h = (_g = this.hostElement) === null || _g === void 0 ? void 0 : _g.style) === null || _h === void 0 ? void 0 : _h.setProperty(`row-gap`, typeof vSpacing === 'number' ? `${vSpacing}px` : vSpacing));
    hasValue(margin) && ((_k = (_j = this.hostElement) === null || _j === void 0 ? void 0 : _j.style) === null || _k === void 0 ? void 0 : _k.setProperty(`--grid-container-margin`, typeof margin === 'number' ? `${margin}px` : margin));
  }
  render() {
    const hostClasses = this.component.getHostBemClasses();
    return h(Host, {
      key: '749923ce70b73db3ff83b14e331801b9b082e454',
      class: hostClasses
    }, h("slot", {
      key: '9cd72594d0355705fcd4c4d2d8a0e8695b998db2'
    }));
  }
  get hostElement() {
    return this;
  }
  static get watchers() {
    return {
      "columns": ["onAttributesChanged"],
      "margin": ["onAttributesChanged"],
      "spacing": ["onAttributesChanged"],
      "horizontalSpacing": ["onAttributesChanged"],
      "verticalSpacing": ["onAttributesChanged"]
    };
  }
  static get style() {
    return OwcGridStyle0;
  }
}, [6, "owc-grid", {
  "columns": [8],
  "margin": [8],
  "spacing": [8],
  "horizontalSpacing": [8, "horizontal-spacing"],
  "verticalSpacing": [8, "vertical-spacing"]
}, [[4, "documentResized", "onDocumentResized"]], {
  "columns": ["onAttributesChanged"],
  "margin": ["onAttributesChanged"],
  "spacing": ["onAttributesChanged"],
  "horizontalSpacing": ["onAttributesChanged"],
  "verticalSpacing": ["onAttributesChanged"]
}]);
__decorate([Context(CTX_KEYS.GRID.COLUMNS)], Grid.prototype, "ctxColumns", void 0);
function defineCustomElement$1() {
  if (typeof customElements === "undefined") {
    return;
  }
  const components = ["owc-grid"];
  components.forEach(tagName => {
    switch (tagName) {
      case "owc-grid":
        if (!customElements.get(tagName)) {
          customElements.define(tagName, Grid);
        }
        break;
    }
  });
}
const OwcGrid = Grid;
const defineCustomElement = defineCustomElement$1;
export { OwcGrid, defineCustomElement };