import { p as proxyCustomElement, H, h, c as Host } from './p-92c4a1ea.js';
import './p-a57042e3.js';
import { a as ComponentUtils } from './p-de830ba7.js';
const contactInfoCss = ".sc-owc-contact-info-h{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;font-family:var(--one-typography-font-family);font-size:var(--one-typography-desktop-subtitle-1-font-size);line-height:var(--one-typography-desktop-subtitle-1-line-height);-webkit-font-kerning:var(--one-typography-desktop-subtitle-1-font-kerning);font-kerning:var(--one-typography-desktop-subtitle-1-font-kerning);font-weight:var(--one-text-font-weight-regular);width:calc(var(--one-spacing-size-7-xs) * 233);padding:var(--one-spacing-padding-2-xs)}.sc-owc-contact-info-h [slot=footer].sc-owc-contact-info{margin-top:var(--one-spacing-gap-4-xs)}.sc-owc-contact-info-h [slot=header].sc-owc-contact-info{font-family:var(--one-typography-font-family);font-size:var(--one-typography-desktop-subtitle-1-font-size);line-height:var(--one-typography-desktop-subtitle-1-line-height);-webkit-font-kerning:var(--one-typography-desktop-subtitle-1-font-kerning);font-kerning:var(--one-typography-desktop-subtitle-1-font-kerning);font-weight:var(--one-text-font-weight-medium)}.owc-contact-info--type-address.sc-owc-contact-info-h{border-top:var(--one-spacing-outline-semibold) solid var(--one-contact-info-color-address-border-top);background:var(--one-contact-info-color-address-background)}.owc-contact-info--type-address.sc-owc-contact-info-h [slot=avatar].sc-owc-contact-info{display:none}.owc-contact-info--type-address.sc-owc-contact-info-h [slot=header].sc-owc-contact-info{margin-bottom:var(--one-spacing-gap-5-xs)}.owc-contact-info--type-avatar.sc-owc-contact-info-h{width:calc(var(--one-spacing-size-7-xs) * 202)}.owc-contact-info--type-avatar.sc-owc-contact-info-h [slot=avatar].sc-owc-contact-info{margin-bottom:var(--one-spacing-gap-2-xs)}";
const OwcContactInfoStyle0 = contactInfoCss;
const ContactInfo = /*@__PURE__*/proxyCustomElement(class ContactInfo extends H {
  constructor() {
    super();
    this.__registerHost();
    this.component = new ComponentUtils(this);
    this.type = 'address';
  }
  render() {
    const hostClasses = this.component.getHostBemClasses({
      [`type-${this.type}`]: true
    });
    return h(Host, {
      key: 'ea83f5031f7a407d80428d270fe60f1ca7b6510a',
      class: hostClasses
    }, h("slot", {
      key: 'ab2aa2d5cf4ca5931955c8972c1fe3e2a8f54c67',
      name: "avatar"
    }), h("slot", {
      key: '2444627deb312828de952465e7434124f5e31114',
      name: "header"
    }), h("slot", {
      key: 'f1fa11c25208e1558d8b1f6f9f6a97ef54580c63'
    }), h("slot", {
      key: '41f84d4ffaa30c46b0b4e0c9f8a037d7d8da465b',
      name: "footer"
    }));
  }
  get hostElement() {
    return this;
  }
  static get style() {
    return OwcContactInfoStyle0;
  }
}, [6, "owc-contact-info", {
  "type": [513]
}]);
function defineCustomElement$1() {
  if (typeof customElements === "undefined") {
    return;
  }
  const components = ["owc-contact-info"];
  components.forEach(tagName => {
    switch (tagName) {
      case "owc-contact-info":
        if (!customElements.get(tagName)) {
          customElements.define(tagName, ContactInfo);
        }
        break;
    }
  });
}
const OwcContactInfo = ContactInfo;
const defineCustomElement = defineCustomElement$1;
export { OwcContactInfo, defineCustomElement };