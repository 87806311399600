import { p as proxyCustomElement, H, d as createEvent, f as forceUpdate, h, c as Host } from './p-92c4a1ea.js';
import { M as MultiChildrenWrapper } from './p-18c82265.js';
import { i as isSameArray } from './p-d7a5de56.js';
import { V as findElementInEventPath, Z as doWhenDefaultIsNotPrevented, am as hasValue, a6 as FocusType, a9 as KeydownHostEvent, aa as KeydownLifecycleMethod, a7 as KeyboardCode, a8 as KeydownAction, ac as KeydownManager, N as getSiblings, b as generateElementId, Q as stopEvent, ai as isDefined } from './p-a57042e3.js';
import { a as accessibilityAttributes, b as accessibility } from './p-756e7dc5.js';
import { a as ComponentUtils } from './p-de830ba7.js';
import { O as OnContextChange } from './p-f5bb9925.js';
import { O as OnElementMutation } from './p-7b2053f2.js';
import { C as CTX_KEYS } from './p-16b4fd0e.js';
const listCss = ".sc-owc-list-h{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;width:100%;max-width:100%;height:-webkit-fit-content;height:-moz-fit-content;height:fit-content;margin:0;padding:0;outline:none;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;list-style-type:none;overflow-x:hidden;overflow-y:auto}@supports (overflow: overlay){.sc-owc-list-h{overflow-y:overlay}}[hidden].sc-owc-list-h{display:none}.sc-owc-list-h>[slot=title].sc-owc-list{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;padding:var(--one-spacing-padding-3-xs) var(--one-spacing-padding-2-xs) var(--one-spacing-padding-none);-ms-flex-align:center;align-items:center;gap:var(--one-spacing-gap-4-xs);font-family:var(--one-typography-font-family);font-size:var(--one-typography-desktop-subtitle-1-font-size);line-height:var(--one-typography-desktop-subtitle-1-line-height);-webkit-font-kerning:var(--one-typography-desktop-subtitle-1-font-kerning);font-kerning:var(--one-typography-desktop-subtitle-1-font-kerning);font-weight:var(--one-text-font-weight-regular);color:var(--one-color-foreground-intensity-medium)}.sc-owc-list-h .owc-list__container.sc-owc-list{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;width:100%;max-width:100%}";
const OwcListStyle0 = listCss;
var __decorate = undefined && undefined.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
const TAG_LIST = 'owc-list';
const TAG_LIST_ITEM = 'owc-list-item';
const List = /*@__PURE__*/proxyCustomElement(class List extends H {
  constructor() {
    super();
    this.__registerHost();
    this.selectedChange = createEvent(this, "selectedChange", 7);
    this.focusChange = createEvent(this, "focusChange", 3);
    this.lastEmittedSelection = undefined;
    this.onListItemClick = event => {
      const item = findElementInEventPath(event, TAG_LIST_ITEM);
      doWhenDefaultIsNotPrevented(event, () => {
        if (this.isRoot && !event.defaultPrevented) {
          this.itemClickHandler(item);
        }
      });
    };
    this.itemClickHandler = item => {
      if (item && !item.disabled && !item.parentDisabled) {
        if (item.leaf) {
          this.handleLeafItemClick(item);
        } else {
          this.handleNonLeafItemClick(item);
        }
        !this.notFocusable && this.hostElement.focus();
      }
    };
    this.menuVisibleGuard = () => {
      const menu = this.hostElement.closest('owc-menu');
      return menu ? !!menu.visible : true;
    };
    this.isListItemSelected = val => hasValue(val) && (this.selected || []).includes(val);
    this.component = new ComponentUtils(this).withKeydownManager({
      selector: () => this.getTabbableListItems(),
      actualSelectedItem: () => this.getTabbableListItems().find(item => (this.selected || []).includes(item.value)),
      circular: true,
      focusType: FocusType.BY_CLASS,
      hostEventHandlers: {
        [KeydownHostEvent.FOCUS]: KeydownHostEvent.FOCUS,
        [KeydownHostEvent.BLUR]: this.noDefaultBlurHandler ? () => undefined : KeydownHostEvent.BLUR,
        [KeydownHostEvent.CLICK]: props => {
          const {
            event,
            managerInstance
          } = props;
          const found = findElementInEventPath(event, TAG_LIST_ITEM);
          found && (managerInstance === null || managerInstance === void 0 ? void 0 : managerInstance.setCurrentItem(found, props, false));
        }
      },
      lifecycleHandlers: {
        [KeydownLifecycleMethod.AFTER_INIT]: KeydownLifecycleMethod.AFTER_INIT,
        [KeydownLifecycleMethod.ITEM_CHANGE]: ({
          items,
          current
        }) => {
          var _a;
          this.focusChange.emit(items[current]);
          this.activeListItemId = ((_a = items[current]) === null || _a === void 0 ? void 0 : _a.id) || undefined;
        }
      },
      keydownHandlers: [{
        key: [KeyboardCode.ArrowDown],
        preventDefault: true,
        handler: KeydownAction.NEXT,
        scrollBehavior: 'top'
      }, {
        key: [KeyboardCode.ArrowUp],
        preventDefault: true,
        handler: KeydownAction.PREVIOUS,
        scrollBehavior: 'top'
      }, {
        key: [KeyboardCode.ArrowRight],
        preventDefault: true,
        scrollBehavior: 'top',
        handler: props => {
          const {
            managerInstance,
            items,
            current
          } = props;
          const currentItem = items[current];
          if (currentItem === undefined) {
            return;
          }
          if (!currentItem.leaf) {
            if (currentItem.expanded) {
              managerInstance.DefaultKeydownHandler[KeydownAction.NEXT](props);
            } else {
              currentItem.expanded = true;
            }
          }
        }
      }, {
        key: [KeyboardCode.ArrowLeft],
        preventDefault: true,
        scrollBehavior: 'top',
        handler: props => {
          var _a;
          const {
            managerInstance,
            items,
            current
          } = props;
          const currentItem = items[current];
          if (currentItem === undefined) {
            return;
          }
          const parentListItem = (_a = currentItem.parentElement) === null || _a === void 0 ? void 0 : _a.closest(TAG_LIST_ITEM);
          if (currentItem.leaf) {
            parentListItem && managerInstance.setCurrentItem(parentListItem, props);
          } else {
            if (currentItem.expanded) {
              currentItem.expanded = false;
            } else {
              parentListItem && managerInstance.setCurrentItem(parentListItem, props);
            }
          }
        }
      }, {
        key: [KeyboardCode.End],
        handler: KeydownAction.LAST,
        scrollBehavior: 'top'
      }, {
        key: [KeyboardCode.Home],
        handler: KeydownAction.FIRST,
        scrollBehavior: 'top'
      }, {
        key: KeydownManager.DefaultKeys[KeydownAction.SELECT],
        handler: props => {
          const item = props.managerInstance.getCurrentItem();
          props.managerInstance.setCurrentItem(item, props);
          this.itemClickHandler(item);
        },
        preventDefault: true,
        scrollBehavior: 'top'
      }, {
        key: [{
          key: '*'
        }],
        handler: ({
          current,
          items
        }) => {
          getSiblings(items[current], el => {
            var _a;
            return ((_a = el === null || el === void 0 ? void 0 : el.tagName) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === TAG_LIST_ITEM.toLowerCase();
          }).forEach(sibling => {
            sibling.expanded = true;
          });
        }
      }]
    });
    this.hostId = generateElementId(this.hostElement);
    this.hostRole = 'list';
    this.size = undefined;
    this.parentSize = undefined;
    this.selected = [];
    this.multiple = undefined;
    this.position = undefined;
    this.showSelectorControl = undefined;
    this.noBorder = undefined;
    this.variant = 'default';
    this.notTabbable = undefined;
    this.noDefaultBlurHandler = false;
    this.notFocusable = false;
    this.isRoot = false;
    this.activeListItemId = undefined;
    this.menuStatus = undefined;
  }
  onMultipleChanged(newValue, oldValue) {
    var _a;
    oldValue && !newValue && ((_a = this.selected) === null || _a === void 0 ? void 0 : _a.length) !== 1 && this.emitSelectedChangeEvent([]);
  }
  onIsRootChanged() {
    const manager = this.component.keydownManager();
    this.isRoot ? manager.attachEventListeners() : manager.detachEventListeners();
  }
  onSelectedChanged(newValue, oldValue) {
    this.isRoot && this.menuVisibleGuard() && this.updateChildrenListAndListItemSelectedState(this.getLists(), this.getListItems());
    this.isRoot && !isSameArray(newValue, oldValue) && this.emitSelectedChangeEvent(newValue);
  }
  onMenuStatusChange(status) {
    this.menuStatus = Object.assign({}, status);
    this.menuVisibleGuard() && this.updateChildrenListAndListItemSelectedState(this.getLists(), this.getListItems());
  }
  onContentChange() {
    if (this.menuVisibleGuard()) {
      const lists = this.getLists();
      const items = this.getListItems();
      this.updateChildrenListAndListItemAttributes(lists, items);
      this.updateChildrenListAndListItemSelectedState(lists, items);
    }
  }
  onExpandedChangeEmittedFromChildList(event) {
    this.isRoot && stopEvent(event);
  }
  onSelectedChangeEmittedFromChildList(event) {
    !this.isRoot && stopEvent(event);
  }
  /* Returns the keydown manager instance */
  getKeydownManager() {
    return Promise.resolve(this.component.keydownManager());
  }
  /* Returns the selected list item's textContent as list */
  async getSelectedItemsTextContent() {
    return Promise.all(this.getListItems().filter(item => item.selected).map(item => item.getTextContent()));
  }
  componentDidLoad() {
    var _a, _b;
    this.onIsRootChanged();
    this.activeListItemId = (_b = (_a = this.component.keydownManager()) === null || _a === void 0 ? void 0 : _a.getCurrentItem()) === null || _b === void 0 ? void 0 : _b.id;
    if (this.menuVisibleGuard()) {
      const lists = this.getLists();
      const items = this.getListItems();
      this.updateChildrenListAndListItemAttributes(lists, items);
      this.updateChildrenListAndListItemSelectedState(lists, items);
      this.propagateAriaAttributesToChildren(items);
    }
  }
  componentWillRender() {
    var _a, _b;
    this.isRoot = !((_b = (_a = this.hostElement) === null || _a === void 0 ? void 0 : _a.parentElement) === null || _b === void 0 ? void 0 : _b.closest(TAG_LIST));
  }
  componentDidUpdate() {
    this.menuVisibleGuard() && this.updateChildrenListAndListItemAttributes(this.getLists(), this.getListItems());
  }
  updateChildrenListAndListItemAttributes(lists, items) {
    const sizeHasValue = hasValue(this.size || this.parentSize);
    const multipleHasValue = hasValue(this.multiple);
    const positionHasValue = hasValue(this.position);
    const noBorderHasValue = hasValue(this.noBorder);
    const showSelectorControlHasValue = hasValue(this.showSelectorControl);
    const variantHasValue = hasValue(this.variant);
    lists === null || lists === void 0 ? void 0 : lists.forEach(list => {
      sizeHasValue && (list.parentSize = this.size || this.parentSize);
      multipleHasValue && (list.multiple = this.multiple);
      positionHasValue && (list.position = this.position);
      noBorderHasValue && (list.noBorder = this.noBorder);
      showSelectorControlHasValue && (list.showSelectorControl = this.showSelectorControl);
      variantHasValue && (list.variant = this.variant);
    });
    items === null || items === void 0 ? void 0 : items.forEach(item => {
      var _a;
      sizeHasValue && (item.parentSize = this.size || this.parentSize);
      multipleHasValue && (item.multiple = this.multiple);
      positionHasValue && (item.position = this.position);
      noBorderHasValue && (item.noBorder = this.noBorder);
      showSelectorControlHasValue && (item.showSelectorControl = this.showSelectorControl);
      variantHasValue && (item.variant = this.variant);
      item.hostRole = this.hostRole === 'menu' ? 'menuitem' : this.hostRole === 'listbox' ? 'option' : 'listitem';
      item.notFocusable = this.notFocusable;
      item.menuItem = !!this.hostElement.closest('owc-menu');
      item.menuVisible = (_a = this.menuStatus) === null || _a === void 0 ? void 0 : _a.visible;
    });
  }
  propagateAriaAttributesToChildren(items) {
    items.forEach((childItem, index, array) => {
      const listItemContainer = childItem.querySelector('.owc-list-item__list-item-container');
      if (listItemContainer) {
        listItemContainer.tabIndex = -1;
      }
      Object.entries(accessibilityAttributes({
        setsize: array.length,
        posinset: index + 1
      })).forEach(([key, value]) => childItem.setAttribute(key, value));
    });
  }
  updateChildrenListAndListItemSelectedState(lists, items) {
    for (const list of lists) {
      list.selected = this.selected || [];
      forceUpdate(list);
    }
    for (const item of items) {
      item.selected = this.isListItemSelected(item.value);
      forceUpdate(item);
    }
  }
  getLists(from = this.hostElement) {
    return Array.from((from === null || from === void 0 ? void 0 : from.querySelectorAll(TAG_LIST)) || []);
  }
  getListItems(from = this.hostElement) {
    return Array.from((from === null || from === void 0 ? void 0 : from.querySelectorAll(TAG_LIST_ITEM)) || []);
  }
  getSelectableListItems(from = this.hostElement) {
    return this.getListItems(from).filter(item => item.selectable);
  }
  getTabbableListItems(from = this.hostElement) {
    return this.getListItems(from).filter(item => item.tabbable);
  }
  handleLeafItemClick(item) {
    let emitEvent = true;
    let newSelectedList = [];
    const isAlreadySelected = this.isListItemSelected(item.value);
    if (isAlreadySelected) {
      if (this.multiple) {
        newSelectedList = [...this.selected].filter(value => value !== item.value);
      } else {
        emitEvent = false;
      }
    } else {
      if (this.multiple) {
        newSelectedList = [...this.selected, item.value];
      } else {
        newSelectedList = [item.value];
      }
    }
    emitEvent && this.emitSelectedChangeEvent(newSelectedList);
  }
  handleNonLeafItemClick(item) {
    let emitEvent = true;
    let newSelectedList = [];
    const childItems = this.getSelectableListItems(item);
    const selectableChildItemValues = childItems.map(it => it.leaf ? it.value : undefined).filter(isDefined);
    if (item.checkboxState === 'indeterminate' || !item.checkboxState) {
      if (this.multiple) {
        newSelectedList = [...this.selected, ...selectableChildItemValues];
      } else {
        emitEvent = false;
      }
    } else {
      newSelectedList = [...this.selected].filter(value => !selectableChildItemValues.includes(value));
    }
    emitEvent && this.emitSelectedChangeEvent(newSelectedList);
  }
  emitSelectedChangeEvent(newSelectedList) {
    if (!isSameArray(this.lastEmittedSelection, newSelectedList)) {
      this.lastEmittedSelection = newSelectedList;
      this.selectedChange.emit(newSelectedList.filter((it, index) => hasValue(it) && newSelectedList.indexOf(it) === index));
    }
  }
  getAccessibilityAttributes() {
    if (this.hostRole === 'menu') {
      return accessibility({
        role: 'menu',
        aria: {
          orientation: 'vertical'
        }
      });
    } else {
      return accessibility({
        role: this.hostRole,
        aria: {
          multiselectable: this.multiple,
          orientation: 'vertical',
          activedescendant: this.activeListItemId
        }
      });
    }
  }
  render() {
    const hostClasses = this.component.getHostBemClasses();
    return h(Host, Object.assign({
      key: '7fa101d6321985e1d1e5bb1ef898ab62b8feeece',
      class: hostClasses,
      tabIndex: this.notTabbable || !this.isRoot ? -1 : this.component.getInitialTabIndex(0),
      onClick: this.onListItemClick
    }, this.getAccessibilityAttributes()), h("slot", {
      key: '4a7770a5ec911bb84c646b600d390aef266f37bc',
      name: "title"
    }), h(MultiChildrenWrapper, {
      key: 'c257c4feff7d35331b464847caf2bb5ff350fa53'
    }, h("slot", {
      key: 'c92d550c0683acd48fce1a7accaf8362dd0cad5e'
    })));
  }
  get hostElement() {
    return this;
  }
  static get watchers() {
    return {
      "multiple": ["onMultipleChanged"],
      "isRoot": ["onIsRootChanged"],
      "selected": ["onSelectedChanged"]
    };
  }
  static get style() {
    return OwcListStyle0;
  }
}, [6, "owc-list", {
  "hostId": [1537, "id"],
  "hostRole": [513, "role"],
  "size": [513],
  "parentSize": [1, "parent-size"],
  "selected": [16],
  "multiple": [516],
  "position": [513],
  "showSelectorControl": [516, "show-selector-control"],
  "noBorder": [516, "no-border"],
  "variant": [513],
  "notTabbable": [4, "not-tabbable"],
  "noDefaultBlurHandler": [4, "no-default-blur-handler"],
  "notFocusable": [4, "not-focusable"],
  "isRoot": [32],
  "activeListItemId": [32],
  "menuStatus": [32],
  "getKeydownManager": [64],
  "getSelectedItemsTextContent": [64]
}, [[0, "expandedChange", "onExpandedChangeEmittedFromChildList"], [0, "selectedChange", "onSelectedChangeEmittedFromChildList"]], {
  "multiple": ["onMultipleChanged"],
  "isRoot": ["onIsRootChanged"],
  "selected": ["onSelectedChanged"]
}]);
__decorate([OnContextChange(CTX_KEYS.MENU.STATUS)], List.prototype, "onMenuStatusChange", null);
__decorate([OnElementMutation({
  target: self => self.hostElement,
  childList: true,
  subtree: true
})], List.prototype, "onContentChange", null);
function defineCustomElement() {
  if (typeof customElements === "undefined") {
    return;
  }
  const components = ["owc-list"];
  components.forEach(tagName => {
    switch (tagName) {
      case "owc-list":
        if (!customElements.get(tagName)) {
          customElements.define(tagName, List);
        }
        break;
    }
  });
}
export { List as L, defineCustomElement as d };