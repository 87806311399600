import { i as isSSR, au as getElement } from './p-a57042e3.js';
const getMouseDownHandlerKey = methodName => `mouseDownHandler-${methodName}`;
const getMouseUpHandlerKey = methodName => `mouseUpHandler-${methodName}`;
const getFocusHandlerKey = methodName => `focusHandler-${methodName}`;
const getBlurHandlerKey = methodName => `blurHandler-${methodName}`;
const getWindowBlurHandlerKey = methodName => `windowBlurHandler-${methodName}`;
const getFocusedViaMouseAttrKey = methodName => `${methodName}-focusedViaMouse`;
const getFocusedViaKeyboardAttrKey = methodName => `${methodName}-focusedViaKeyboard`;
const getPrevActiveElementAttrKey = methodName => `${methodName}-prevActiveElement`;
const getLastFocusedViaMouseAttrKey = methodName => `${methodName}-lastFocusedViaMouse`;
function removeListeners(self, target, methodName) {
  target.removeEventListener('mousedown', self[getMouseDownHandlerKey(methodName)], true);
  target.removeEventListener('mouseup', self[getMouseUpHandlerKey(methodName)], true);
  target.removeEventListener('focus', self[getFocusHandlerKey(methodName)], true);
  target.removeEventListener('blur', self[getBlurHandlerKey(methodName)], true);
  !isSSR() && window.removeEventListener('blur', self[getWindowBlurHandlerKey(methodName)], true);
}
function addListeners(self, target, methodName) {
  removeListeners(self, target, methodName);
  target.addEventListener('mousedown', self[getMouseDownHandlerKey(methodName)], true);
  target.addEventListener('mouseup', self[getMouseUpHandlerKey(methodName)], true);
  target.addEventListener('focus', self[getFocusHandlerKey(methodName)], true);
  target.addEventListener('blur', self[getBlurHandlerKey(methodName)], true);
  !isSSR() && window.addEventListener('blur', self[getWindowBlurHandlerKey(methodName)], true);
}
const initializeAndAddListeners = (self, opts, methodName) => {
  var _a;
  const method = self[methodName];
  const host = getElement(self);
  const target = (opts === null || opts === void 0 ? void 0 : opts.targetElement) ? (_a = opts === null || opts === void 0 ? void 0 : opts.targetElement) === null || _a === void 0 ? void 0 : _a.call(opts, self) : host;
  if (!target) {
    return;
  }
  self[getFocusedViaMouseAttrKey(methodName)] = false;
  self[getFocusedViaKeyboardAttrKey(methodName)] = false;
  self[getPrevActiveElementAttrKey(methodName)] = null;
  self[getLastFocusedViaMouseAttrKey(methodName)] = false;
  self[getMouseDownHandlerKey(methodName)] = () => {
    self[getFocusedViaMouseAttrKey(methodName)] = true;
    self[getLastFocusedViaMouseAttrKey(methodName)] = true;
    self[getFocusedViaKeyboardAttrKey(methodName)] = false;
    method.call(self, false);
  };
  self[getMouseUpHandlerKey(methodName)] = () => {
    self[getFocusedViaMouseAttrKey(methodName)] = false;
  };
  self[getFocusHandlerKey(methodName)] = () => {
    if (target === null || target === void 0 ? void 0 : target.isSameNode(self[getPrevActiveElementAttrKey(methodName)])) {
      if (self[getLastFocusedViaMouseAttrKey(methodName)]) {
        return;
      }
    } else {
      self[getLastFocusedViaMouseAttrKey(methodName)] = self[getFocusedViaMouseAttrKey(methodName)];
    }
    self[getPrevActiveElementAttrKey(methodName)] = target;
    self[getFocusedViaKeyboardAttrKey(methodName)] = !self[getFocusedViaMouseAttrKey(methodName)];
    method.call(self, self[getFocusedViaKeyboardAttrKey(methodName)]);
  };
  self[getBlurHandlerKey(methodName)] = () => {
    self[getFocusedViaKeyboardAttrKey(methodName)] = false;
    self[getPrevActiveElementAttrKey(methodName)] = null;
    method.call(self, false);
  };
  self[getWindowBlurHandlerKey(methodName)] = () => {
    !isSSR() && (self[getPrevActiveElementAttrKey(methodName)] = document.activeElement);
  };
  addListeners(self, target, methodName);
};
function FocusVisible(opts) {
  return (proto, methodName) => {
    const {
      componentDidLoad,
      connectedCallback,
      disconnectedCallback
    } = proto;
    proto.componentDidLoad = function () {
      initializeAndAddListeners(this, opts, methodName);
      return componentDidLoad === null || componentDidLoad === void 0 ? void 0 : componentDidLoad.call(this);
    };
    proto.connectedCallback = function () {
      initializeAndAddListeners(this, opts, methodName);
      return connectedCallback === null || connectedCallback === void 0 ? void 0 : connectedCallback.call(this);
    };
    proto.disconnectedCallback = function () {
      var _a;
      const host = getElement(this);
      const target = (opts === null || opts === void 0 ? void 0 : opts.targetElement) ? (_a = opts === null || opts === void 0 ? void 0 : opts.targetElement) === null || _a === void 0 ? void 0 : _a.call(opts, this) : host;
      target && removeListeners(this, target, methodName);
      return disconnectedCallback === null || disconnectedCallback === void 0 ? void 0 : disconnectedCallback.call(this);
    };
  };
}
export { FocusVisible as F };