var __spreadArray = this && this.__spreadArray || function (r, e, t) {
  if (t || arguments.length === 2) for (var u = 0, i = e.length, n; u < i; u++) {
    if (n || !(u in e)) {
      if (!n) n = Array.prototype.slice.call(e, 0, u);
      n[u] = e[u];
    }
  }
  return r.concat(n || Array.prototype.slice.call(e));
};
import { ai as isDefined } from "./MutationObserverSafe-08b9629b.js";
var FocusTrapQueryItemBuilder = function () {
  function r() {
    this._attributes = [];
  }
  r.create = function () {
    return new r();
  };
  r.prototype.build = function () {
    return __spreadArray(__spreadArray([this._tag], this._attributes, true), this._defaults ? [':not([tabindex^="-"])', ":not([disabled])", ":not([aria-hidden])", ":not([data-skip-focus])"] : [], true).filter(Boolean).join("");
  };
  r.prototype.tag = function (r) {
    this._tag = r;
    return this;
  };
  r.prototype.has = function (r, e) {
    var t = [r, isDefined(e) ? '"'.concat(e, '"') : ""].filter(Boolean).join("=");
    this._attributes.push("[".concat(t, "]"));
    return this;
  };
  r.prototype.not = function (r, e) {
    var t = [r, isDefined(e) ? '"'.concat(e, '"') : ""].filter(Boolean).join("=");
    this._attributes.push(":not([".concat(t, "])"));
    return this;
  };
  r.prototype.startWith = function (r, e) {
    var t = [r, isDefined(e) ? '"'.concat(e, '"') : ""].filter(Boolean).join("^=");
    this._attributes.push("[".concat(t, "]"));
    return this;
  };
  r.prototype.notStartWith = function (r, e) {
    var t = [r, isDefined(e) ? '"'.concat(e, '"') : ""].filter(Boolean).join("^=");
    this._attributes.push(":not([".concat(t, "])"));
    return this;
  };
  r.prototype.withDefaults = function () {
    this._defaults = true;
    return this;
  };
  return r;
}();
var FocusTrapQueryBuilder = function () {
  function r() {
    this._items = [];
  }
  r.create = function () {
    return new r();
  };
  r.prototype.build = function () {
    return this._items.map(function (r) {
      return r.build();
    }).filter(Boolean).join(", ");
  };
  r.prototype.add = function (r) {
    this._items.push(r);
    return this;
  };
  r.getNativeElementQuery = function () {
    return r.create().add(FocusTrapQueryItemBuilder.create().tag("input").not("type", "hidden").withDefaults()).add(FocusTrapQueryItemBuilder.create().tag("textarea").withDefaults()).add(FocusTrapQueryItemBuilder.create().tag("button").withDefaults()).add(FocusTrapQueryItemBuilder.create().tag("a").withDefaults());
  };
  r.getPredefinedTrappedElementsQuery = function () {
    return r.create().add(FocusTrapQueryItemBuilder.create().tag("a").has("href").withDefaults()).add(FocusTrapQueryItemBuilder.create().tag("area").has("href").withDefaults()).add(FocusTrapQueryItemBuilder.create().tag("input").not("type", "hidden").withDefaults()).add(FocusTrapQueryItemBuilder.create().tag("select").withDefaults()).add(FocusTrapQueryItemBuilder.create().tag("textarea").withDefaults()).add(FocusTrapQueryItemBuilder.create().tag("button").withDefaults()).add(FocusTrapQueryItemBuilder.create().tag("iframe")).add(FocusTrapQueryItemBuilder.create().tag("object")).add(FocusTrapQueryItemBuilder.create().tag("embed")).add(FocusTrapQueryItemBuilder.create().has("tabindex").withDefaults()).add(FocusTrapQueryItemBuilder.create().has("tabindex", 0)).add(FocusTrapQueryItemBuilder.create().has("contenteditable"));
  };
  return r;
}();
export { FocusTrapQueryItemBuilder as F, FocusTrapQueryBuilder as a };