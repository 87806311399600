import { p as proxyCustomElement, H, h, c as Host } from './p-92c4a1ea.js';
import './p-a57042e3.js';
import { b as accessibility } from './p-756e7dc5.js';
import { a as ComponentUtils } from './p-de830ba7.js';
const skeletonLoaderCss = ".sc-owc-skeleton-loader-h{position:relative;display:-ms-flexbox;display:flex;width:100%;height:var(--one-spacing-size-s);background-color:var(--one-color-interaction-default-intensity-low);border-radius:var(--one-spacing-radius-4-xs);overflow:hidden}.owc-skeleton-loader--alternate.sc-owc-skeleton-loader-h{background-color:var(--one-color-interaction-default-intensity-lower)}.owc-skeleton-loader--variant-circle.sc-owc-skeleton-loader-h{border-radius:var(--one-spacing-radius-100)}.owc-skeleton-loader--animation.sc-owc-skeleton-loader-h::after{position:absolute;top:0;right:0;bottom:0;left:0;-webkit-transform:translateX(-100%);transform:translateX(-100%);background-image:-webkit-gradient(linear, left top, right top, color-stop(0, rgba(255, 255, 255, 0)), color-stop(20%, rgba(255, 255, 255, 0.2)), color-stop(60%, rgba(255, 255, 255, 0.5)), to(rgba(255, 255, 255, 0)));background-image:linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));-webkit-animation:shimmer 2s infinite;animation:shimmer 2s infinite;content:\"\"}@-webkit-keyframes shimmer{100%{-webkit-transform:translateX(100%);transform:translateX(100%)}}@keyframes shimmer{100%{-webkit-transform:translateX(100%);transform:translateX(100%)}}@media (forced-colors: active){.sc-owc-skeleton-loader-h{outline:var(--one-spacing-outline-regular) solid highlighttext;background:highlighttext}.owc-skeleton-loader--animation.sc-owc-skeleton-loader-h{background:unset}.owc-skeleton-loader--animation.sc-owc-skeleton-loader-h::after{background:highlighttext}}";
const OwcSkeletonLoaderStyle0 = skeletonLoaderCss;
const SkeletonLoader = /*@__PURE__*/proxyCustomElement(class SkeletonLoader extends H {
  constructor() {
    super();
    this.__registerHost();
    this.component = new ComponentUtils(this);
    this.variant = 'text';
    this.animation = true;
    this.alternate = false;
  }
  render() {
    const hostClasses = this.component.getHostBemClasses({
      [`variant-${this.variant || 'text'}`]: true,
      animation: this.animation,
      alternate: this.alternate
    });
    return h(Host, Object.assign({
      key: '3be8037060c58160e626e91a7aadcadd98281a60',
      class: hostClasses,
      tabIndex: -1
    }, accessibility({
      role: 'progressbar',
      aria: {
        disabled: true
      }
    })));
  }
  get hostElement() {
    return this;
  }
  static get style() {
    return OwcSkeletonLoaderStyle0;
  }
}, [2, "owc-skeleton-loader", {
  "variant": [513],
  "animation": [516],
  "alternate": [516]
}]);
function defineCustomElement$1() {
  if (typeof customElements === "undefined") {
    return;
  }
  const components = ["owc-skeleton-loader"];
  components.forEach(tagName => {
    switch (tagName) {
      case "owc-skeleton-loader":
        if (!customElements.get(tagName)) {
          customElements.define(tagName, SkeletonLoader);
        }
        break;
    }
  });
}
const OwcSkeletonLoader = SkeletonLoader;
const defineCustomElement = defineCustomElement$1;
export { OwcSkeletonLoader, defineCustomElement };